import React, { useState, useEffect, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Box, Typography, Button, TextField, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import config from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

const PlaidConnection = () => {
    const [linkToken, setLinkToken] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth0();

    const fetchBalance = useCallback(async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/api/plaid/balance`, {
                params: { userId: user.sub }
            });
            setBalance(response.data.balance);
        } catch (error) {
            console.error('Error fetching balance:', error);
            setError('Failed to fetch balance. Please try again later.');
        }
    }, [user?.sub]); // Added user.sub as dependency

    useEffect(() => {
        const fetchLinkToken = async () => {
            try {
                const response = await axios.post(`${config.apiUrl}/api/plaid/create_link_token`, { userId: user.sub });
                setLinkToken(response.data.link_token);
            } catch (error) {
                console.error('Error fetching link token:', error);
                setError('Failed to fetch link token. Please try again later.');
            }
        };

        const checkConnection = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/api/plaid/is_connected`, {
                    params: { userId: user.sub }
                });
                setIsConnected(response.data.isConnected);
                if (response.data.isConnected) {
                    await fetchBalance();
                }
            } catch (error) {
                console.error('Error checking connection:', error);
                setError('Failed to check connection status. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (user) {
            fetchLinkToken();
            checkConnection();
        } else {
            setError('User ID is missing. Please ensure you are logged in.');
            setIsLoading(false);
        }
    }, [user, fetchBalance]); // Added fetchBalance as dependency

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: async (public_token, metadata) => {
            try {
                await axios.post(`${config.apiUrl}/api/plaid/set_access_token`, {
                    publicToken: public_token,
                    userId: user.sub
                });
                setIsConnected(true);
                await fetchBalance();
            } catch (error) {
                console.error('Error setting access token:', error);
                setError('Failed to connect your account. Please try again.');
            }
        },
    });

    const handleTransfer = async (type) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${config.apiUrl}/api/plaid/${type}`, {
                userId: user.sub,
                amount: parseFloat(amount)
            });
            setBalance(response.data.newBalance);
            setAmount('');
        } catch (error) {
            console.error(`Error processing ${type}:`, error);
            setError(`Failed to process ${type}. Please try again later.`);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Plaid Connection</Typography>
            {!isConnected ? (
                <Button
                    onClick={() => open()}
                    disabled={!ready || !linkToken}
                >
                    Connect Your Bank Account
                </Button>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Your account is connected
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Balance: ${balance.toFixed(2)}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <TextField
                            type="number"
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            sx={{ mr: 2 }}
                        />
                        <Button
                            onClick={() => handleTransfer('deposit')}
                            disabled={isLoading || !amount}
                            sx={{ mr: 1 }}
                        >
                            Deposit
                        </Button>
                        <Button
                            onClick={() => handleTransfer('withdraw')}
                            disabled={isLoading || !amount || parseFloat(amount) > balance}
                        >
                            Withdraw
                        </Button>
                    </Box>
                    {isLoading && <CircularProgress sx={{ mt: 2 }} />}
                </>
            )}
        </Box>
    );
};

export default PlaidConnection;