// src/components/Auth/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { notify } from '../../utils/notifications';
import PageTransition from '../PageTransition/PageTransition';
import PageSkeleton from '../LoadingSkeleton/PageSkeleton';

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
    const {
        isAuthenticated,
        loginWithRedirect,
        getAccessTokenSilently,
        user,
        isLoading
    } = useAuth0();
    const location = useLocation();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            if (isAuthenticated && user) {
                try {
                    // Get fresh token
                    await getAccessTokenSilently();

                    // Check roles
                    const userRoles = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`] || [];
                    const hasRequiredRole = requiredRoles.length === 0 ||
                        requiredRoles.some(role => userRoles.includes(role));

                    setIsAuthorized(hasRequiredRole);
                } catch (error) {
                    console.error('Error checking authentication:', error);
                    notify.error('Authentication error. Please try again.');
                    setIsAuthorized(false);
                }
            }
            setIsChecking(false);
        };

        checkAuth();

        // Set up token refresh interval
        const tokenRefreshInterval = setInterval(async () => {
            if (isAuthenticated) {
                try {
                    await getAccessTokenSilently({ ignoreCache: true });
                } catch (error) {
                    console.error('Error refreshing token:', error);
                    notify.error('Session expired. Please log in again.');
                    loginWithRedirect();
                }
            }
        }, 10 * 60 * 1000); // Refresh every 10 minutes

        return () => clearInterval(tokenRefreshInterval);
    }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, user, requiredRoles]);

    if (isLoading || isChecking) {
        return <PageSkeleton type={location.pathname.split('/')[1] || 'dashboard'} />;
    }

    if (!isAuthenticated) {
        sessionStorage.setItem('intendedPath', location.pathname);
        loginWithRedirect();
        return null;
    }

    if (!isAuthorized) {
        notify.error('You do not have permission to access this page');
        return <Navigate to="/dashboard" />;
    }

    return (
        <PageTransition>
            {children}
        </PageTransition>
    );
};

export default ProtectedRoute;