import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';

// Import the sub-components
import DirectDeposit from '../components/settings/DirectDeposit';
import Security from '../components/settings/Security';
import ProfileSettings from '../components/settings/ProfileSettings';
import Privacy from '../components/settings/Privacy';
import Activity from '../components/settings/Activity';
import ApiSettings from "../components/settings/ApiSettings";
import PlaidConnection from "../components/settings/PlaidConnection";

const SettingsContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    width: '100%',
    maxWidth: '800px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const SettingsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isSubRouteActive = (route) => {
        return location.pathname.includes(`/settings/${route}`);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: '20px',
            mt: 8,  // Add margin-top to push the content down
        }}>
            <Typography variant="h4" sx={{ m: 2, textAlign: 'center' }}>Settings</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
                maxWidth: '800px',
                boxSizing: 'border-box',
            }}>
                <StyledButton
                    onClick={() => navigate('profile')}
                    variant={isSubRouteActive('profile') ? 'contained' : 'outlined'}
                >
                    Profile
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('direct-deposit')}
                    variant={isSubRouteActive('direct-deposit') ? 'contained' : 'outlined'}
                >
                    Direct Deposit
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('security')}
                    variant={isSubRouteActive('security') ? 'contained' : 'outlined'}
                >
                    Security
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('privacy')}
                    variant={isSubRouteActive('privacy') ? 'contained' : 'outlined'}
                >
                    Privacy
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('activity')}
                    variant={isSubRouteActive('activity') ? 'contained' : 'outlined'}
                >
                    Activity
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('api')}
                    variant={isSubRouteActive('api') ? 'contained' : 'outlined'}
                >
                    API
                </StyledButton>
                <StyledButton
                    onClick={() => navigate('plaid')}
                    variant={isSubRouteActive('plaid') ? 'contained' : 'outlined'}
                >
                    Plaid Connection
                </StyledButton>
            </Box>
            <SettingsContainer elevation={3}>
                <Routes>
                    <Route index element={<ProfileSettings />} />
                    <Route path="profile" element={<ProfileSettings />} />
                    <Route path="direct-deposit" element={<DirectDeposit />} />
                    <Route path="security" element={<Security />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="activity" element={<Activity />} />
                    <Route path="api" element={<ApiSettings />} />
                    <Route path="plaid" element={<PlaidConnection userId="your-user-id" />} />
                </Routes>
            </SettingsContainer>
        </Box>
    );
};

export default SettingsPage;