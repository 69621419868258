import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CallbackPage = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                if (!isLoading) {
                    console.log('Callback - Auth state:', {
                        isAuthenticated,
                        isLoading,
                        user,
                        userRoles: user?.['https://dev-wrmiox1vscj57fii.us.auth0.com/roles'],
                        userMetadata: user?.['https://dev-wrmiox1vscj57fii.us.auth0.com/user_metadata'],
                        fullUser: user
                    });

                    if (isAuthenticated) {
                        try {
                            const token = await getAccessTokenSilently({ detailedResponse: true });
                            console.log('Token details:', token);
                        } catch (tokenError) {
                            console.error('Token retrieval error:', tokenError);
                        }

                        const intendedPath = sessionStorage.getItem('intendedPath');
                        console.log('Callback - Redirecting to:', intendedPath || '/dashboard');

                        // Clear the stored path
                        sessionStorage.removeItem('intendedPath');

                        navigate(intendedPath || '/dashboard', { replace: true });
                    } else {
                        console.log('Callback - Not authenticated, redirecting to home');
                        navigate('/', { replace: true });
                    }
                }
            } catch (error) {
                console.error('Callback error:', error);
                navigate('/', { replace: true });
            }
        };

        handleCallback();
    }, [isLoading, isAuthenticated, navigate, getAccessTokenSilently, user]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    );
};

export default CallbackPage;