import React, { useState } from 'react';
import {
    Typography,
    Switch,
    FormControlLabel,
    Button,
    Box,
    Modal,
    Paper,
} from '@mui/material';

const Privacy = () => {
    const [settings, setSettings] = useState({
        shareWithAdvertisers: false,
        dataCollection: false,
        emailNotifications: false,
    });

    const [openPolicy, setOpenPolicy] = useState(false);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setSettings({ ...settings, [name]: checked });
    };

    const handleSave = () => {
        console.log('Privacy settings saved:', settings);
    };

    const handleViewPrivacyPolicy = () => {
        setOpenPolicy(true);
    };

    const handleClosePolicy = () => {
        setOpenPolicy(false);
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
                Privacy Settings
            </Typography>

            <FormControlLabel
                control={
                    <Switch
                        checked={settings.shareWithAdvertisers}
                        onChange={handleChange}
                        name="shareWithAdvertisers"
                    />
                }
                label="Allow sharing of data with advertisers"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={settings.dataCollection}
                        onChange={handleChange}
                        name="dataCollection"
                    />
                }
                label="Enable data collection for analytics"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={settings.emailNotifications}
                        onChange={handleChange}
                        name="emailNotifications"
                    />
                }
                label="Receive email notifications related to privacy"
            />

            <Box sx={{ mt: 3 }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save Privacy Settings
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleViewPrivacyPolicy} sx={{ ml: 2 }}>
                    View Privacy Policy
                </Button>
            </Box>

            <Modal
                open={openPolicy}
                onClose={handleClosePolicy}
                aria-labelledby="privacy-policy-title"
                aria-describedby="privacy-policy-description"
            >
                <Paper sx={{ p: 4, m: 'auto', mt: 10, maxWidth: '80vw', maxHeight: '80vh', overflowY: 'auto' }}>
                    <Typography variant="h6" id="privacy-policy-title" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography id="privacy-policy-description" variant="body1" component="div">
                        <p><strong>Last updated:</strong> 2024.08.10</p>
                        <p>
                            Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect
                            your information when you use our services.
                        </p>
                        <p><strong>Information We Collect</strong></p>
                        <p>
                            We may collect the following types of information:
                        </p>
                        <ul>
                            <li>Personal identification information (Name, email address, phone number, etc.)</li>
                            <li>Financial information (Bank account details for direct deposits)</li>
                            <li>Usage data (How you interact with our service)</li>
                        </ul>
                        <p><strong>How We Use Your Information</strong></p>
                        <p>
                            The information we collect is used to:
                        </p>
                        <ul>
                            <li>Provide and maintain our service</li>
                            <li>Improve user experience</li>
                            <li>Process transactions and send relevant updates</li>
                            <li>Comply with legal obligations</li>
                        </ul>
                        <p><strong>Sharing of Information</strong></p>
                        <p>
                            We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
                            Information unless we provide users with advance notice. This does not include website hosting
                            partners and other parties who assist us in operating our website, conducting our business, or
                            serving our users.
                        </p>
                        <p><strong>Data Security</strong></p>
                        <p>
                            We implement a variety of security measures to maintain the safety of your personal information
                            when you enter, submit, or access your personal information.
                        </p>
                        <p><strong>Your Privacy Choices</strong></p>
                        <p>
                            You may choose to limit the data you share with us. However, certain features of our service may be
                            affected if you choose to do so.
                        </p>
                        <p><strong>Changes to This Policy</strong></p>
                        <p>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
                            new Privacy Policy on this page.
                        </p>
                        <p><strong>Contact Us</strong></p>
                        <p>
                            If you have any questions about this Privacy Policy, please contact us at privacy@radix.com.
                        </p>
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleClosePolicy} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Paper>
            </Modal>
        </Box>
    );
};

export default Privacy;
