import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CryptoTicker.css';
import arrowUp from './arrow-up.png';
import arrowDown from './arrow-down.png';

const CryptoTicker = () => {
    const [cryptoData, setCryptoData] = useState([]);
    const [priceChanges, setPriceChanges] = useState({});

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            const response = await axios.get('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,USDT,BNB,SOL,XRP,USDC,ADA,STETH,AVAX&tsyms=USD');
            const cryptos = Object.entries(response.data).map(([key, value]) => ({
                symbol: key,
                price: value.USD
            }));
            localStorage.setItem('cryptoData', JSON.stringify(cryptos));
            localStorage.setItem('lastFetchTime', new Date().getTime().toString());
            setCryptoData(cryptos);
            calculatePriceChanges(cryptos);
        };

        const isValidCryptoData = (data) => {
            return Array.isArray(data) && data.every(item => typeof item.symbol === 'string' && !isNaN(parseFloat(item.price)));
        };

        const getCryptoData = async () => {
            try {
                const cachedData = localStorage.getItem('cryptoData');
                const lastFetchTime = localStorage.getItem('lastFetchTime');
                const currentTime = new Date().getTime();
                const twentyFourHours = 24 * 60 * 60 * 1000;

                if (cachedData && lastFetchTime && currentTime - parseInt(lastFetchTime, 10) < twentyFourHours) {
                    const parsedData = JSON.parse(cachedData);
                    if (isValidCryptoData(parsedData)) {
                        // Use the cached data
                        setCryptoData(parsedData);
                        calculatePriceChanges(parsedData);
                    } else {
                        // Fetch new data if cached data is invalid
                        await fetchDataFromAPI();
                    }
                } else {
                    // Fetch new data if no valid cache is found
                    await fetchDataFromAPI();
                }
            } catch (error) {
                console.error('Error fetching crypto data:', error);
            }
        };

        getCryptoData();
    }, []);

    const calculatePriceChanges = (currentData) => {
        const changes = currentData.reduce((acc, crypto) => {
            const previousData = JSON.parse(localStorage.getItem('previousCryptoData'));
            if (previousData && previousData[crypto.symbol]) {
                const previousPrice = previousData[crypto.symbol].price;
                const change = ((crypto.price - previousPrice) / previousPrice * 100).toFixed(2);
                acc[crypto.symbol] = change;
            } else {
                acc[crypto.symbol] = '0.00';
            }
            return acc;
        }, {});
        setPriceChanges(changes);
    };

    return (
        <div className="ticker-container">
            {cryptoData.map((crypto, index) => {
                const change = priceChanges[crypto.symbol];
                const arrowImageSrc = change >= 0 ? arrowUp : arrowDown;
                const arrowClassName = `arrow ${change >= 0 ? 'up-arrow' : 'down-arrow'}`;

                return (
                    <div key={index} className="ticker-item">
                        {crypto.symbol}: ${parseFloat(crypto.price).toFixed(2)} USD
                        <span className={`change ${change >= 0 ? 'up' : 'down'}`}>
                            <img src={arrowImageSrc} className={arrowClassName} alt="Change" />
                            {change}%
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default CryptoTicker;
