// src/components/LoadingSkeleton/PageSkeleton.js
import React from 'react';
import { Box, Skeleton, Paper } from '@mui/material';
import { styled } from '@mui/system';

const SkeletonContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    background: theme.palette.background.paper,
}));

const PageSkeleton = ({ type = "dashboard" }) => {
    const renderDashboardSkeleton = () => (
        <Box sx={{ width: '100%' }}>
            {/* Header Section */}
            <Box sx={{ mb: 4 }}>
                <Skeleton variant="rectangular" width="30%" height={40} />
            </Box>

            {/* Stats Cards */}
            <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                {[1, 2, 3, 4].map((item) => (
                    <SkeletonContainer key={item} sx={{ flex: 1 }}>
                        <Skeleton variant="text" width="60%" height={24} />
                        <Skeleton variant="rectangular" height={40} sx={{ mt: 1 }} />
                    </SkeletonContainer>
                ))}
            </Box>

            {/* Chart Area */}
            <SkeletonContainer>
                <Skeleton variant="rectangular" height={300} />
            </SkeletonContainer>

            {/* Table */}
            <SkeletonContainer sx={{ mt: 2 }}>
                <Skeleton variant="rectangular" height={40} sx={{ mb: 1 }} />
                {[1, 2, 3, 4, 5].map((item) => (
                    <Skeleton key={item} variant="rectangular" height={40} sx={{ my: 1 }} />
                ))}
            </SkeletonContainer>
        </Box>
    );

    const renderTradingSkeleton = () => (
        <Box sx={{ width: '100%' }}>
            {/* Trading Form */}
            <SkeletonContainer>
                <Skeleton variant="text" width="20%" height={32} />
                <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
                    <Skeleton variant="rectangular" width="48%" height={56} />
                    <Skeleton variant="rectangular" width="48%" height={56} />
                </Box>
                <Skeleton variant="rectangular" width="30%" height={40} sx={{ mt: 2 }} />
            </SkeletonContainer>

            {/* Order Books */}
            <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                <SkeletonContainer sx={{ flex: 1 }}>
                    <Skeleton variant="text" width="40%" height={24} />
                    {[1, 2, 3, 4, 5].map((item) => (
                        <Skeleton key={item} variant="rectangular" height={32} sx={{ my: 1 }} />
                    ))}
                </SkeletonContainer>
                <SkeletonContainer sx={{ flex: 1 }}>
                    <Skeleton variant="text" width="40%" height={24} />
                    {[1, 2, 3, 4, 5].map((item) => (
                        <Skeleton key={item} variant="rectangular" height={32} sx={{ my: 1 }} />
                    ))}
                </SkeletonContainer>
            </Box>
        </Box>
    );

    const renderSettingsSkeleton = () => (
        <Box sx={{ width: '100%' }}>
            <SkeletonContainer>
                <Skeleton variant="text" width="20%" height={32} />
                {[1, 2, 3, 4].map((item) => (
                    <Box key={item} sx={{ my: 3 }}>
                        <Skeleton variant="text" width="30%" height={24} />
                        <Skeleton variant="rectangular" height={56} sx={{ mt: 1 }} />
                    </Box>
                ))}
                <Skeleton variant="rectangular" width="20%" height={40} sx={{ mt: 2 }} />
            </SkeletonContainer>
        </Box>
    );

    return (
        <Box sx={{
            padding: 3,
            animation: 'fadeIn 0.5s ease-in-out',
            '@keyframes fadeIn': {
                '0%': {
                    opacity: 0,
                },
                '100%': {
                    opacity: 1,
                },
            },
        }}>
            {type === "dashboard" && renderDashboardSkeleton()}
            {type === "trading" && renderTradingSkeleton()}
            {type === "settings" && renderSettingsSkeleton()}
        </Box>
    );
};

export default PageSkeleton;