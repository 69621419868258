import React, { useState } from 'react';
import { Typography, Switch, Button, Box, List, ListItem, ListItemText, Divider } from '@mui/material';

const Security = () => {
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [trustedDevices, setTrustedDevices] = useState([
        { id: 1, name: 'My iPhone', lastUsed: '2024-08-10' },
        { id: 2, name: 'My MacBook', lastUsed: '2024-08-09' },
    ]);
    const [connectedSessions, setConnectedSessions] = useState([
        { id: 1, location: 'New York, USA', device: 'Chrome on Windows 10', lastActive: '2 hours ago' },
        { id: 2, location: 'San Francisco, USA', device: 'Safari on iPhone', lastActive: 'Yesterday' },
    ]);

    const handle2FAToggle = () => {
        setIs2FAEnabled((prev) => !prev);
    };

    const handleRemoveDevice = (deviceId) => {
        setTrustedDevices(trustedDevices.filter(device => device.id !== deviceId));
    };

    const handleSignOutSession = (sessionId) => {
        setConnectedSessions(connectedSessions.filter(session => session.id !== sessionId));
    };

    const handleRemoveAllDevices = () => {
        setTrustedDevices([]);
    };

    const handleSignOutAllSessions = () => {
        setConnectedSessions([]);
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
                Security Settings
            </Typography>

            {/* Two-Factor Authentication */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6">Two-Factor Authentication (2FA)</Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Enhance your account security by enabling 2FA.
                </Typography>
                <Switch
                    checked={is2FAEnabled}
                    onChange={handle2FAToggle}
                    color="primary"
                />
                {is2FAEnabled && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            2FA is enabled. Use the authentication app on your mobile device to scan the QR code and activate 2FA.
                        </Typography>
                    </Box>
                )}
            </Box>

            <Divider />

            {/* Trusted Devices */}
            <Box sx={{ my: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Trusted Devices</Typography>
                    <Button variant="outlined" color="secondary" onClick={handleRemoveAllDevices}>
                        Remove All Devices
                    </Button>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Manage devices that you have marked as trusted.
                </Typography>
                <List>
                    {trustedDevices.map((device) => (
                        <ListItem key={device.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ListItemText
                                primary={device.name}
                                secondary={`Last used: ${device.lastUsed}`}
                            />
                            <Button variant="contained" color="secondary" onClick={() => handleRemoveDevice(device.id)}>
                                Remove
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Divider />

            {/* Connected Sessions */}
            <Box sx={{ my: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Connected Sessions</Typography>
                    <Button variant="outlined" color="secondary" onClick={handleSignOutAllSessions}>
                        Sign Out All Sessions
                    </Button>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Review and manage your active sessions.
                </Typography>
                <List>
                    {connectedSessions.map((session) => (
                        <ListItem key={session.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ListItemText
                                primary={`${session.device}`}
                                secondary={`${session.location} - Last active: ${session.lastActive}`}
                            />
                            <Button variant="contained" color="secondary" onClick={() => handleSignOutSession(session.id)}>
                                Sign Out
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default Security;
