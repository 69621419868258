import React, { useState, useEffect } from 'react';
import { TimestreamQuery } from '@aws-sdk/client-timestream-query';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TradeAnalytics = ({ userId }) => {
    const [trades, setTrades] = useState([]);
    const [performanceMetrics, setPerformanceMetrics] = useState({
        totalTrades: 0,
        profitableTrades: 0,
        totalPnL: 0,
        winRate: 0,
        averageTradeSize: 0,
    });

    useEffect(() => {
        const fetchTradeData = async () => {
            const client = new TimestreamQuery({
                region: "us-east-1",
                credentials: fromCognitoIdentityPool({
                    client: new CognitoIdentityClient({ region: "us-east-1" }),
                    identityPoolId: "YOUR_COGNITO_IDENTITY_POOL_ID"
                })
            });

            const oneDayAgo = new Date(Date.now() - 86400000).toISOString();
            const query = `
                SELECT time, symbol, side, price, quantity, pnl
                FROM "YourDatabase"."TradesTable"
                WHERE user_id = '${userId}'
                AND time > '${oneDayAgo}'
                ORDER BY time DESC
            `;

            try {
                const result = await client.query({ QueryString: query });
                const formattedTrades = result.Rows.map(row => ({
                    time: new Date(row.Data[0].ScalarValue).toLocaleString(),
                    symbol: row.Data[1].ScalarValue,
                    side: row.Data[2].ScalarValue,
                    price: parseFloat(row.Data[3].ScalarValue),
                    quantity: parseInt(row.Data[4].ScalarValue),
                    pnl: parseFloat(row.Data[5].ScalarValue),
                }));
                setTrades(formattedTrades);
                calculatePerformanceMetrics(formattedTrades);
            } catch (error) {
                console.error("Error fetching trade data:", error);
            }
        };

        fetchTradeData();
    }, [userId]);

    const calculatePerformanceMetrics = (trades) => {
        const totalTrades = trades.length;
        const profitableTrades = trades.filter(trade => trade.pnl > 0).length;
        const totalPnL = trades.reduce((sum, trade) => sum + trade.pnl, 0);
        const winRate = (profitableTrades / totalTrades) * 100;
        const averageTradeSize = trades.reduce((sum, trade) => sum + (trade.price * trade.quantity), 0) / totalTrades;

        setPerformanceMetrics({
            totalTrades,
            profitableTrades,
            totalPnL: totalPnL.toFixed(2),
            winRate: winRate.toFixed(2),
            averageTradeSize: averageTradeSize.toFixed(2),
        });
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>Trade Analytics</Typography>

            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>Performance Summary</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Total Trades: {performanceMetrics.totalTrades}</Typography>
                    <Typography>Profitable Trades: {performanceMetrics.profitableTrades}</Typography>
                    <Typography>Total P&L: ${performanceMetrics.totalPnL}</Typography>
                    <Typography>Win Rate: {performanceMetrics.winRate}%</Typography>
                    <Typography>Avg Trade Size: ${performanceMetrics.averageTradeSize}</Typography>
                </Box>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>P&L Over Time</Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={trades}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="pnl" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>

            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>Recent Trades</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Symbol</TableCell>
                                <TableCell>Side</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>P&L</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trades.map((trade, index) => (
                                <TableRow key={index}>
                                    <TableCell>{trade.time}</TableCell>
                                    <TableCell>{trade.symbol}</TableCell>
                                    <TableCell>{trade.side}</TableCell>
                                    <TableCell>${trade.price.toFixed(2)}</TableCell>
                                    <TableCell>{trade.quantity}</TableCell>
                                    <TableCell>${trade.pnl.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default TradeAnalytics;