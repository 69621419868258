import React, { useState, useEffect, useMemo } from 'react';
import {
    Typography, Grid, Paper, Box, Chip,
    CircularProgress, Alert, IconButton,
    List, ListItem, ListItemText, ListItemIcon,
    ToggleButton, ToggleButtonGroup,
    Menu, MenuItem, Button,
    Tooltip as MuiTooltip,
    Card, CardContent,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableSortLabel,
    Select, FormControl, InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import {
    TrendingUp,
    TrendingDown,
    ArrowUp,
    ArrowDown,
    RefreshCcw,
    Star,
    Calendar,
    ChevronDown,
    Info,
    DollarSign,
    Percent,
    Clock
} from 'lucide-react';
import {
    LineChart, Line, AreaChart, Area, PieChart, Pie,
    XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Cell
} from 'recharts';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const DashboardContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: '#f5f5f5',
    minHeight: 'calc(100vh - 64px)',
    marginTop: '64px'
}));

const MetricCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:hover': {
        boxShadow: theme.shadows[4]
    }
}));

const StyledChip = styled(Chip)(({ theme, positive }) => ({
    marginLeft: theme.spacing(1),
    backgroundColor: positive ? '#e6f4ea' : '#fce8e8',
    color: positive ? '#137333' : '#c5221f',
    '& .MuiChip-icon': {
        color: 'inherit'
    }
}));

const TopMoversCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    height: '400px',
    overflowY: 'auto'
}));

const TimeframeToggle = styled(ToggleButtonGroup)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiToggleButton-root': {
        textTransform: 'none',
        fontSize: '0.875rem'
    }
}));

const ChartControls = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
}));

const MetricTooltipCard = styled(Card)(({ theme }) => ({
    maxWidth: 300,
    '& .MuiCardContent-root': {
        padding: theme.spacing(1.5),
    }
}));

const StatsTable = styled(TableContainer)(({ theme }) => ({
    '& .MuiTableCell-root': {
        padding: theme.spacing(0.5, 1),
        fontSize: '0.875rem',
    }
}));

const HoldingsTable = styled(TableContainer)(({ theme }) => ({
    maxHeight: 400,
    overflow: 'auto',
    '& .MuiTableCell-root': {
        padding: theme.spacing(1),
    },
    '& .MuiTableSortLabel-root.Mui-active': {
        color: theme.palette.primary.main,
    }
}));

const TopMoversFilter = styled(FormControl)(({ theme }) => ({
    minWidth: 120,
    marginBottom: theme.spacing(2)
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

// Helper component for metric tooltips
const MetricTooltipContent = ({ title, stats }) => (
    <MetricTooltipCard>
        <CardContent>
            <Typography variant="subtitle2" gutterBottom>
                {title}
            </Typography>
            <StatsTable>
                <Table size="small">
                    <TableBody>
                        {stats.map((stat, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {stat.label}
                                </TableCell>
                                <TableCell align="right">
                                    {stat.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StatsTable>
        </CardContent>
    </MetricTooltipCard>
);

const DashboardPage = () => {
    const { getAccessTokenSilently, user } = useAuth0();

    // Existing state
    const [portfolioData, setPortfolioData] = useState({
        totalBalance: 813420.69,
        dailyChange: 4.2,
        weeklyChange: 12.5,
        monthlyChange: -2.3,
        assets: [
            { name: 'Bitcoin', value: 65570.88, holdings: '1.5 BTC', allocation: 45 },
            { name: 'Ethereum', value: 24431.10, holdings: '10 ETH', allocation: 35 },
            { name: 'Solana', value: 15000.00, holdings: '100 SOL', allocation: 20 }
        ]
    });
    const [topMovers, setTopMovers] = useState([]);
    const [watchlist, setWatchlist] = useState([
        { name: 'Solana', price: 150.30, change: 5.67, watched: true },
        { name: 'ChainLink', price: 17.30, change: 20.10, watched: true }
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Chart state
    const [timeframe, setTimeframe] = useState('1M');
    const [dateRange, setDateRange] = useState([
        dayjs().subtract(1, 'month'),
        dayjs()
    ]);
    const [chartType, setChartType] = useState('area');
    const [chartMetric, setChartMetric] = useState('value');
    const [anchorEl, setAnchorEl] = useState(null);

    // New interactive feature state
    const [holdingSort, setHoldingSort] = useState({
        field: 'value',
        direction: 'desc'
    });
    const [moverFilter, setMoverFilter] = useState('all'); // 'all', 'gainers', 'losers'
    const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);

    // Historical data generation (keeping your existing implementation)
    const historicalData = useMemo(() => {
        const baseValue = 800000;
        const dataPoints = 30;

        return Array.from({ length: dataPoints }, (_, i) => {
            const trend = i * (baseValue * 0.001);
            const volatility = (Math.random() - 0.5) * baseValue * 0.02;
            const value = baseValue + trend + volatility;
            const returnValue = ((value - baseValue) / baseValue) * 100;

            return {
                date: dayjs().subtract(dataPoints - i - 1, 'days').format('YYYY-MM-DD'),
                value: Math.round(value),
                return: Math.round(returnValue * 100) / 100
            };
        });
    }, []);

    // Filtered data calculations
    const filteredData = useMemo(() => {
        return historicalData.filter(item => {
            const itemDate = dayjs(item.date);
            return itemDate.isAfter(dateRange[0]) && itemDate.isBefore(dateRange[1]);
        });
    }, [historicalData, dateRange]);

    // Sorted holdings calculation
    const sortedHoldings = useMemo(() => {
        return [...portfolioData.assets].sort((a, b) => {
            const multiplier = holdingSort.direction === 'asc' ? 1 : -1;
            switch (holdingSort.field) {
                case 'value':
                    return multiplier * (a.value - b.value);
                case 'allocation':
                    return multiplier * (a.allocation - b.allocation);
                case 'name':
                    return multiplier * a.name.localeCompare(b.name);
                default:
                    return 0;
            }
        });
    }, [portfolioData.assets, holdingSort]);

    // Filtered movers calculation
    const filteredMovers = useMemo(() => {
        if (moverFilter === 'all') return watchlist;
        return watchlist.filter(item =>
            moverFilter === 'gainers' ? item.change > 0 : item.change < 0
        );
    }, [watchlist, moverFilter]);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            // Your existing fetch logic
            setError(null);
        } catch (err) {
            setError('Failed to fetch dashboard data');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    // Event handlers
    const handleTimeframeChange = (_, newTimeframe) => {
        if (newTimeframe !== null) {
            setTimeframe(newTimeframe);
            const now = dayjs();
            switch (newTimeframe) {
                case '1W':
                    setDateRange([now.subtract(1, 'week'), now]);
                    break;
                case '1M':
                    setDateRange([now.subtract(1, 'month'), now]);
                    break;
                case '3M':
                    setDateRange([now.subtract(3, 'month'), now]);
                    break;
                case '1Y':
                    setDateRange([now.subtract(1, 'year'), now]);
                    break;
                case 'ALL':
                    setDateRange([now.subtract(5, 'year'), now]);
                    break;
                default:
                    break;
            }
        }
    };

    const handleMetricMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMetricMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMetricChange = (metric) => {
        setChartMetric(metric);
        handleMetricMenuClose();
    };

    const handleSortChange = (field) => {
        setHoldingSort(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleAssetClick = (asset, event) => {
        setSelectedAsset(asset);
        setTooltipAnchorEl(event.currentTarget);
    };

    const handleTooltipClose = () => {
        setSelectedAsset(null);
        setTooltipAnchorEl(null);
    };

    const renderMetricCard = (title, value, change, prefix = '$') => {
        const getTooltipStats = () => {
            switch (title) {
                case 'Total Balance':
                    return [
                        { label: 'Available Balance', value: `$${(value * 0.95).toLocaleString()}` },
                        { label: 'Pending Orders', value: `$${(value * 0.05).toLocaleString()}` },
                        { label: "Today's High", value: `$${(value * 1.02).toLocaleString()}` },
                        { label: "Today's Low", value: `$${(value * 0.98).toLocaleString()}` }
                    ];
                case '24h Change':
                    return [
                        { label: 'Absolute Change', value: `$${(portfolioData.totalBalance * change / 100).toLocaleString()}` },
                        { label: 'Number of Trades', value: '12' },
                        { label: 'Best Performing', value: 'BTC (+5.2%)' },
                        { label: 'Worst Performing', value: 'SOL (-2.1%)' }
                    ];
                case '7d Change':
                    return [
                        { label: 'Week Start Balance', value: `$${(value * 0.9).toLocaleString()}` },
                        { label: 'Week High', value: `$${(value * 1.15).toLocaleString()}` },
                        { label: 'Week Low', value: `$${(value * 0.85).toLocaleString()}` },
                        { label: 'Average Daily Change', value: '+2.1%' }
                    ];
                case '30d Change':
                    return [
                        { label: 'Month Start Balance', value: `$${(value * 0.95).toLocaleString()}` },
                        { label: 'Month High', value: `$${(value * 1.2).toLocaleString()}` },
                        { label: 'Month Low', value: `$${(value * 0.8).toLocaleString()}` },
                        { label: 'Volatility', value: 'Medium' }
                    ];
                default:
                    return [];
            }
        };

        return (
            <MuiTooltip
                title={<MetricTooltipContent title={title} stats={getTooltipStats()} />}
                arrow
                placement="bottom"
            >
                <MetricCard>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" color="textSecondary">
                            {title}
                        </Typography>
                        <IconButton size="small" onClick={fetchDashboardData}>
                            <RefreshCcw size={16} />
                        </IconButton>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        {prefix}{typeof value === 'number' ? value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }) : value}
                    </Typography>
                    {change !== undefined && (
                        <StyledChip
                            size="small"
                            icon={change >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
                            label={`${change >= 0 ? '+' : ''}${change}%`}
                            positive={change >= 0}
                        />
                    )}
                </MetricCard>
            </MuiTooltip>
        );
    };

    const renderPortfolioChart = () => (
        <MetricCard>
            <ChartControls>
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="h6">Portfolio Performance</Typography>
                    <Button
                        size="small"
                        onClick={handleMetricMenuOpen}
                        endIcon={<ChevronDown size={16} />}
                        sx={{ textTransform: 'none' }}
                    >
                        {chartMetric === 'value' ? 'Value' : 'Return'}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMetricMenuClose}
                    >
                        <MenuItem onClick={() => handleMetricChange('value')}>Value</MenuItem>
                        <MenuItem onClick={() => handleMetricChange('return')}>Return %</MenuItem>
                    </Menu>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                    <TimeframeToggle
                        value={timeframe}
                        exclusive
                        onChange={handleTimeframeChange}
                        size="small"
                    >
                        <ToggleButton value="1W">1W</ToggleButton>
                        <ToggleButton value="1M">1M</ToggleButton>
                        <ToggleButton value="3M">3M</ToggleButton>
                        <ToggleButton value="1Y">1Y</ToggleButton>
                        <ToggleButton value="ALL">ALL</ToggleButton>
                    </TimeframeToggle>
                </Box>
            </ChartControls>
            <Box sx={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={filteredData}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) => dayjs(date).format('MMM DD')}
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis
                            tickFormatter={(value) =>
                                chartMetric === 'value'
                                    ? `$${(value / 1000).toFixed(0)}k`
                                    : `${value.toFixed(1)}%`
                            }
                            tick={{ fontSize: 12 }}
                            domain={chartMetric === 'value' ? ['auto', 'auto'] : [-20, 20]}
                        />
                        <Tooltip
                            formatter={(value) =>
                                chartMetric === 'value'
                                    ? [`$${Number(value).toLocaleString()}`, 'Value']
                                    : [`${value}%`, 'Return']
                            }
                        />
                        <Area
                            type="monotone"
                            dataKey={chartMetric}
                            stroke="#8884d8"
                            fill="url(#colorValue)"
                            fillOpacity={0.3}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </MetricCard>
    );

    const renderHoldingsTable = () => (
        <MetricCard>
            <Typography variant="h6" gutterBottom>Holdings</Typography>
            <HoldingsTable>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={holdingSort.field === 'name'}
                                    direction={holdingSort.direction}
                                    onClick={() => handleSortChange('name')}
                                >
                                    Asset
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={holdingSort.field === 'value'}
                                    direction={holdingSort.direction}
                                    onClick={() => handleSortChange('value')}
                                >
                                    Value
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={holdingSort.field === 'allocation'}
                                    direction={holdingSort.direction}
                                    onClick={() => handleSortChange('allocation')}
                                >
                                    Allocation
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedHoldings.map((asset, index) => (
                            <TableRow
                                key={index}
                                hover
                                onClick={(e) => handleAssetClick(asset, e)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell>{asset.name}</TableCell>
                                <TableCell align="right">
                                    ${asset.value.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                    <StyledChip
                                        label={`${asset.allocation}%`}
                                        size="small"
                                        positive={true}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </HoldingsTable>
        </MetricCard>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DashboardContainer>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                <Grid container spacing={3}>
                    {/* Metrics Overview */}
                    <Grid item xs={12} md={3}>
                        {renderMetricCard('Total Balance', portfolioData.totalBalance, portfolioData.dailyChange)}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {renderMetricCard('24h Change', portfolioData.dailyChange, null, '')}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {renderMetricCard('7d Change', portfolioData.weeklyChange, null, '')}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {renderMetricCard('30d Change', portfolioData.monthlyChange, null, '')}
                    </Grid>

                    {/* Portfolio Chart */}
                    <Grid item xs={12} md={8}>
                        {renderPortfolioChart()}
                    </Grid>

                    {/* Top Movers with Filter */}
                    <Grid item xs={12} md={4}>
                        <TopMoversCard>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography variant="h6">Top Movers</Typography>
                                <Box display="flex" gap={1}>
                                    <FormControl size="small">
                                        <Select
                                            value={moverFilter}
                                            onChange={(e) => setMoverFilter(e.target.value)}
                                            variant="outlined"
                                            sx={{ minWidth: 120 }}
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="gainers">Gainers</MenuItem>
                                            <MenuItem value="losers">Losers</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <IconButton size="small" onClick={fetchDashboardData}>
                                        <RefreshCcw size={16} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <List>
                                {filteredMovers.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton edge="end" onClick={() => {}}>
                                                {item.watched ?
                                                    <Star size={16} fill="#000"/> :
                                                    <Star size={16} />
                                                }
                                            </IconButton>
                                        }
                                    >
                                        <ListItemIcon>
                                            {item.change >= 0 ? (
                                                <ArrowUp color="#137333" />
                                            ) : (
                                                <ArrowDown color="#c5221f" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={`$${item.price} (${item.change}%)`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </TopMoversCard>
                    </Grid>

                    {/* Asset Allocation */}
                    <Grid item xs={12} md={6}>
                        <MetricCard>
                            <Typography variant="h6" gutterBottom>Asset Allocation</Typography>
                            <Box height={300} display="flex" justifyContent="center">
                                <ResponsiveContainer>
                                    <PieChart>
                                        <Pie
                                            data={portfolioData.assets}
                                            dataKey="allocation"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={30}
                                            outerRadius={60}
                                            labelLine={false}
                                            label={({
                                                        cx,
                                                        cy,
                                                        midAngle,
                                                        innerRadius,
                                                        outerRadius,
                                                        value,
                                                        name
                                                    }) => {
                                                const RADIAN = Math.PI / 180;
                                                const radius = outerRadius * 1.4;
                                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                                return (
                                                    <text
                                                        x={x}
                                                        y={y}
                                                        fill="#333"
                                                        textAnchor={x > cx ? 'start' : 'end'}
                                                        dominantBaseline="central"
                                                        fontSize="12px"
                                                    >
                                                        {`${name} (${value}%)`}
                                                    </text>
                                                );
                                            }}
                                        >
                                            {portfolioData.assets.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip
                                            formatter={(value) => [`${value}%`, 'Allocation']}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Box>
                        </MetricCard>
                    </Grid>

                    {/* Holdings Table */}
                    <Grid item xs={12} md={6}>
                        {renderHoldingsTable()}
                    </Grid>
                </Grid>

                {/* Asset Detail Tooltip */}
                <MuiTooltip
                    open={Boolean(selectedAsset)}
                    onClose={handleTooltipClose}
                    title={
                        selectedAsset && (
                            <MetricTooltipContent
                                title={selectedAsset.name}
                                stats={[
                                    { label: 'Holdings', value: selectedAsset.holdings },
                                    { label: 'Value', value: `$${selectedAsset.value.toLocaleString()}` },
                                    { label: 'Allocation', value: `${selectedAsset.allocation}%` },
                                    { label: '24h Change', value: `${(Math.random() * 10 - 5).toFixed(2)}%` }
                                ]}
                            />
                        )
                    }
                    arrow
                    placement="right"
                    anchorEl={tooltipAnchorEl}
                >
                    <span /> {/* Empty span for tooltip anchor */}
                </MuiTooltip>
            </DashboardContainer>
        </LocalizationProvider>
    );
};

export default DashboardPage;