import React, { useState, useEffect, useMemo } from 'react';
import {
    Typography, Grid, Paper, Box, Tab, Tabs,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Button, CircularProgress,
    Alert, Chip, Tooltip, Select, MenuItem,
    FormControl, InputLabel, IconButton
} from '@mui/material';
import {
    LineChart, Line,
    XAxis, YAxis, CartesianGrid,
    ResponsiveContainer, Legend, ReferenceLine,
    RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
    BarChart, Bar
} from 'recharts';
import { styled } from '@mui/system';
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from 'dayjs';
import {
    TrendingUp, TrendingDown, AlertTriangle,
    RefreshCcw, Info
} from 'lucide-react';

// Styled Components
const AnalyticsCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
        boxShadow: theme.shadows[4]
    }
}));

const MetricChip = styled(Chip)(({ theme, positive }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: positive ? '#e6f4ea' : '#fce8e8',
    color: positive ? '#137333' : '#c5221f',
    '& .MuiChip-icon': {
        color: 'inherit'
    }
}));

const TimeframeSelect = styled(FormControl)(({ theme }) => ({
    minWidth: 120,
    marginBottom: theme.spacing(2)
}));

const DataGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2)
}));

// Helper Functions
const calculateSharpeRatio = (returns, riskFreeRate = 0.02) => {
    const meanReturn = returns.reduce((sum, r) => sum + r, 0) / returns.length;
    const stdDev = Math.sqrt(
        returns.reduce((sum, r) => sum + Math.pow(r - meanReturn, 2), 0) / returns.length
    );
    return (meanReturn - riskFreeRate) / stdDev;
};

const calculateMaxDrawdown = (values) => {
    let maxDrawdown = 0;
    let peak = values[0];

    values.forEach(value => {
        if (value > peak) {
            peak = value;
        }
        const drawdown = (peak - value) / peak;
        if (drawdown > maxDrawdown) {
            maxDrawdown = drawdown;
        }
    });

    return maxDrawdown;
};

const calculateBeta = (portfolioReturns, marketReturns) => {
    const covariance = calculateCovariance(portfolioReturns, marketReturns);
    const marketVariance = calculateVariance(marketReturns);
    return covariance / marketVariance;
};

const calculateCovariance = (array1, array2) => {
    const mean1 = array1.reduce((sum, val) => sum + val, 0) / array1.length;
    const mean2 = array2.reduce((sum, val) => sum + val, 0) / array2.length;

    return array1.reduce((sum, val, index) =>
        sum + (val - mean1) * (array2[index] - mean2), 0
    ) / array1.length;
};

const calculateVariance = (array) => {
    const mean = array.reduce((sum, val) => sum + val, 0) / array.length;
    return array.reduce((sum, val) =>
        sum + Math.pow(val - mean, 2), 0
    ) / array.length;
};

const PortfolioAnalytics = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [timeframe, setTimeframe] = useState('1M');
    const [currentTab, setCurrentTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Portfolio State
    const [portfolioData, setPortfolioData] = useState({
        totalValue: 813420.69,
        returns: {
            daily: 2.5,
            weekly: 7.8,
            monthly: 15.4,
            yearly: 42.1
        },
        historicalValues: [],
        assets: [
            { name: 'Bitcoin', value: 65570.88, allocation: 45, risk: 0.85 },
            { name: 'Ethereum', value: 24431.10, allocation: 35, risk: 0.92 },
            { name: 'Solana', value: 15000.00, allocation: 20, risk: 1.15 }
        ]
    });

    // Risk Metrics State
    const [riskMetrics, setRiskMetrics] = useState({
        sharpeRatio: 1.8,
        sortino: 2.1,
        beta: 0.85,
        alpha: 5.2,
        maxDrawdown: -12.5,
        volatility: 14.2,
        valueAtRisk: 25000
    });

    // Correlation Data
    const [correlationData, setCorrelationData] = useState({
        btc: { correlation: 0.85, risk: 0.9 },
        eth: { correlation: 0.78, risk: 0.95 },
        spy: { correlation: 0.32, risk: 0.45 }
    });

    // Historical Performance Data
    const [performanceData, setPerformanceData] = useState([]);

    useEffect(() => {
        fetchPortfolioData();
    }, [timeframe]);

    const fetchPortfolioData = async () => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            // Simulate API call with mock data
            const mockHistoricalData = generateMockHistoricalData();
            setPerformanceData(mockHistoricalData);

            // Calculate risk metrics
            const returns = mockHistoricalData.map(d => d.return);
            const sharpeRatio = calculateSharpeRatio(returns);
            const maxDrawdown = calculateMaxDrawdown(mockHistoricalData.map(d => d.value));

            setRiskMetrics(prev => ({
                ...prev,
                sharpeRatio: sharpeRatio.toFixed(2),
                maxDrawdown: (maxDrawdown * 100).toFixed(2)
            }));

            setError(null);
        } catch (err) {
            setError('Failed to fetch portfolio data');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const generateMockHistoricalData = () => {
        const data = [];
        const periods = timeframe === '1W' ? 7 : timeframe === '1M' ? 30 : 90;
        let value = 800000;

        for (let i = 0; i < periods; i++) {
            const date = dayjs().subtract(periods - i - 1, 'days');
            const dailyReturn = (Math.random() - 0.5) * 0.02; // -1% to 1% daily return
            value = value * (1 + dailyReturn);

            data.push({
                date: date.format('YYYY-MM-DD'),
                value: Math.round(value),
                return: dailyReturn * 100,
                benchmark: Math.round(800000 * (1 + (Math.random() - 0.5) * 0.015))
            });
        }

        return data;
    };

    const handleTimeframeChange = (event) => {
        setTimeframe(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    // Render helper functions
    const renderMetricCard = (title, value, change, info) => (
        <AnalyticsCard>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="textSecondary">
                    {title}
                    <Tooltip title={info} arrow placement="top">
                        <Info size={16} style={{ marginLeft: 8, cursor: 'pointer' }} />
                    </Tooltip>
                </Typography>
                <IconButton size="small" onClick={fetchPortfolioData}>
                    <RefreshCcw size={16} />
                </IconButton>
            </Box>
            <Typography variant="h4" sx={{ my: 2 }}>
                {value}
            </Typography>
            {change !== undefined && (
                <MetricChip
                    size="small"
                    icon={change >= 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
                    label={`${change >= 0 ? '+' : ''}${change}%`}
                    positive={change >= 0}
                />
            )}
        </AnalyticsCard>
    );

    const renderRiskMetricsPanel = () => (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                {renderMetricCard(
                    'Sharpe Ratio',
                    riskMetrics.sharpeRatio,
                    null,
                    'Risk-adjusted return metric. Higher is better. >1 is good, >2 is very good.'
                )}
            </Grid>
            <Grid item xs={12} md={3}>
                {renderMetricCard(
                    'Beta',
                    riskMetrics.beta,
                    null,
                    'Portfolio volatility compared to market. 1 = market volatility, <1 = less volatile.'
                )}
            </Grid>
            <Grid item xs={12} md={3}>
                {renderMetricCard(
                    'Max Drawdown',
                    `${riskMetrics.maxDrawdown}%`,
                    null,
                    'Largest peak-to-trough decline. Lower is better.'
                )}
            </Grid>
            <Grid item xs={12} md={3}>
                {renderMetricCard(
                    'Value at Risk (95%)',
                    `$${riskMetrics.valueAtRisk.toLocaleString()}`,
                    null,
                    'Potential loss in value over a day with 95% confidence.'
                )}
            </Grid>
            <Grid item xs={12}>
                <AnalyticsCard>
                    <Typography variant="h6" gutterBottom>Risk Metrics Over Time</Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={performanceData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(date) => dayjs(date).format('MMM DD')}
                            />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                tickFormatter={(value) => `$${(value/1000).toFixed(0)}k`}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={(value) => `${value.toFixed(1)}%`}
                            />
                            <Tooltip
                                formatter={(value, name) => [
                                    name.includes('Value') ?
                                        `$${Number(value).toLocaleString()}` :
                                        `${value.toFixed(2)}%`,
                                    name
                                ]}
                            />
                            <Legend />
                            <Line
                                yAxisId="left"
                                type="monotone"
                                dataKey="value"
                                stroke="#8884d8"
                                name="Portfolio Value"
                            />
                            <Line
                                yAxisId="right"
                                type="monotone"
                                dataKey="return"
                                stroke="#82ca9d"
                                name="Daily Return"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </AnalyticsCard>
            </Grid>
        </Grid>
    );

    const renderPerformancePanel = () => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AnalyticsCard>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6">Portfolio Performance vs Benchmark</Typography>
                        <TimeframeSelect size="small">
                            <Select
                                value={timeframe}
                                onChange={handleTimeframeChange}
                                variant="outlined"
                            >
                                <MenuItem value="1W">1 Week</MenuItem>
                                <MenuItem value="1M">1 Month</MenuItem>
                                <MenuItem value="3M">3 Months</MenuItem>
                            </Select>
                        </TimeframeSelect>
                    </Box>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={performanceData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(date) => dayjs(date).format('MMM DD')}
                            />
                            <YAxis
                                tickFormatter={(value) => `$${(value/1000).toFixed(0)}k`}
                            />
                            <Tooltip
                                formatter={(value) => [`$${value.toLocaleString()}`, 'Value']}
                            />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#8884d8"
                                name="Portfolio"
                                dot={false}
                            />
                            <Line
                                type="monotone"
                                dataKey="benchmark"
                                stroke="#82ca9d"
                                name="Benchmark"
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </AnalyticsCard>
            </Grid>
            <Grid item xs={12} md={6}>
                <AnalyticsCard>
                    <Typography variant="h6" gutterBottom>Return Distribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={performanceData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="return"
                                tickFormatter={(value) => `${value.toFixed(1)}%`}
                            />
                            <YAxis />
                            <Tooltip
                                formatter={(value) => [`${value}`, 'Frequency']}
                            />
                            <Bar dataKey="frequency" fill="#8884d8" />
                            <ReferenceLine x={0} stroke="#666" />
                        </BarChart>
                    </ResponsiveContainer>
                </AnalyticsCard>
            </Grid>
            <Grid item xs={12} md={6}>
                <AnalyticsCard>
                    <Typography variant="h6" gutterBottom>Risk Attribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <RadarChart data={portfolioData.assets}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="name" />
                            <PolarRadiusAxis />
                            <Radar
                                name="Risk"
                                dataKey="risk"
                                stroke="#8884d8"
                                fill="#8884d8"
                                fillOpacity={0.6}
                            />
                        </RadarChart>
                    </ResponsiveContainer>
                </AnalyticsCard>
            </Grid>
        </Grid>
    );

    const renderCorrelationPanel = () => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AnalyticsCard>
                    <Typography variant="h6" gutterBottom>Asset Correlation Matrix</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Asset</TableCell>
                                    {portfolioData.assets.map(asset => (
                                        <TableCell key={asset.name}>{asset.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {portfolioData.assets.map((asset, i) => (
                                    <TableRow key={asset.name}>
                                        <TableCell component="th" scope="row">
                                            {asset.name}
                                        </TableCell>
                                        {portfolioData.assets.map((_, j) => (
                                            <TableCell key={j}>
                                                {i === j ? '1.00' :
                                                    (0.3 + Math.random() * 0.4).toFixed(2)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AnalyticsCard>
            </Grid>
        </Grid>
    );
    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4">Portfolio Analytics</Typography>
                <Button
                    variant="outlined"
                    startIcon={<RefreshCcw size={16} />}
                    onClick={fetchPortfolioData}
                    disabled={isLoading}
                >
                    Refresh
                </Button>
            </Box>

            {error && (
                <Alert
                    severity="error"
                    sx={{ mb: 3 }}
                    onClose={() => setError(null)}
                >
                    {error}
                </Alert>
            )}

            {/* Overview Cards */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={3}>
                    {renderMetricCard(
                        'Total Portfolio Value',
                        `$${portfolioData.totalValue.toLocaleString()}`,
                        portfolioData.returns.daily
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {renderMetricCard(
                        'Monthly Return',
                        `${portfolioData.returns.monthly}%`,
                        portfolioData.returns.monthly
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {renderMetricCard(
                        'Risk Score',
                        riskMetrics.beta,
                        null,
                        'Portfolio risk level compared to market benchmark'
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {renderMetricCard(
                        'Risk-Adjusted Return',
                        riskMetrics.sharpeRatio,
                        null,
                        'Sharpe Ratio: Return per unit of risk'
                    )}
                </Grid>
            </Grid>

            {/* Main Content Tabs */}
            <Paper sx={{ mb: 3 }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Performance" />
                    <Tab label="Risk Metrics" />
                    <Tab label="Correlations" />
                </Tabs>
            </Paper>

            {/* Loading State */}
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                    <CircularProgress />
                </Box>
            ) : (
                /* Tab Panels */
                <Box sx={{ mt: 3 }}>
                    {currentTab === 0 && renderPerformancePanel()}
                    {currentTab === 1 && renderRiskMetricsPanel()}
                    {currentTab === 2 && renderCorrelationPanel()}
                </Box>
            )}

            {/* Portfolio Insights */}
            <Paper sx={{ p: 3, mt: 3 }}>
                <Typography variant="h6" gutterBottom>Portfolio Insights</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <AlertTriangle size={20} style={{ marginRight: 8 }} />
                            <Typography variant="subtitle2">Risk Level</Typography>
                        </Box>
                        <Typography variant="body2">
                            Your portfolio shows {riskMetrics.beta < 1 ? 'lower' : 'higher'} volatility
                            than the market with a beta of {riskMetrics.beta}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <TrendingUp size={20} style={{ marginRight: 8 }} />
                            <Typography variant="subtitle2">Performance</Typography>
                        </Box>
                        <Typography variant="body2">
                            Your portfolio has {portfolioData.returns.monthly > 0 ? 'gained' : 'lost'} {
                            Math.abs(portfolioData.returns.monthly)}% this month
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Info size={20} style={{ marginRight: 8 }} />
                            <Typography variant="subtitle2">Recommendation</Typography>
                        </Box>
                        <Typography variant="body2">
                            Consider {riskMetrics.sharpeRatio < 1 ? 'rebalancing your portfolio to improve' : 'maintaining your current'} risk-adjusted returns
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default PortfolioAnalytics;