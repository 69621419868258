import React from 'react';
import { Grid } from '@mui/material';
import HeroSection from '../Hero/HeroSection';
import FeaturesSection from '../Features/FeaturesSection';
import HowItWorksSection from '../HowItWorks/HowItWorksSection';
import TestimonialsSection from '../Testimonials/TestimonialsSection';
import LatestNewsSection from '../LatestNews/LatestNewsSection';
import Footer from '../Footer/Footer';
import './HomePage.css';

const HomePage = () => {
    return (
        <div className="page-container">
            <div className="content-wrap">
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <HeroSection />
                    </Grid>
                    <Grid item>
                        <FeaturesSection />
                    </Grid>
                    <Grid item>
                        <HowItWorksSection />
                    </Grid>
                    <Grid item>
                        <TestimonialsSection />
                    </Grid>
                    <Grid item>
                        <LatestNewsSection />
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;