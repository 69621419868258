import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

const stepsData = [
    {
        title: 'Create an Account',
        description: 'Sign up in minutes to start trading digital assets.',
    },
    {
        title: 'Deposit Funds',
        description: 'Easily deposit cryptocurrency or fiat to fund your trading account.',
    },
    {
        title: 'Start Trading',
        description: 'Buy and sell a wide range of digital assets with a user-friendly interface.',
    },
    {
        title: 'Automate Strategies',
        description: 'Implement advanced trading strategies with our automated tools.',
    },
    {
        title: 'Withdraw Profits',
        description: 'Withdraw your earnings quickly and securely at any time.',
    },
];

const StepItem = ({ title, description }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Paper elevation={3} sx={{ padding: 2, margin: 1, height: '100%' }}>
            <Typography variant="h6" component="h3" gutterBottom>
                {title}
            </Typography>
            <Typography>
                {description}
            </Typography>
        </Paper>
    </Grid>
);

const HowItWorksSection = () => (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Typography variant="h4" component="h2" gutterBottom>
            How It Works
        </Typography>
        <Grid container spacing={2}>
            {stepsData.map((step, index) => (
                <StepItem key={index} title={step.title} description={step.description} />
            ))}
        </Grid>
    </Box>
);

export default HowItWorksSection;
