import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import HomePage from './components/HomePage/HomePage';
import TradingPage from './pages/TradingPage';
import DashboardPage from './pages/DashboardPage';
import NavBar from './components/NavBar/NavBar';
import CryptoTicker from './components/CryptoTicker/CryptoTicker';
import SettingsPage from './pages/SettingsPage';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import SessionWarning from './components/Auth/SessionWarning';
import CallbackPage from './components/Auth/CallbackPage';
import { ROLES, PERMISSIONS } from './utils/auth';
import './App.css';
import PortfolioAnalytics from "./components/PortfolioAnalytics/PortfolioAnalytics";

const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: '#ff0000',
        },
        background: {
            default: '#fff',
        },
    },
});

const App = () => {
    const origin = window.location.origin.includes('localhost')
        ? 'https://radix.trading'
        : window.location.origin;

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: `${origin}/auth/callback`,
                audience: 'http://radix-ui.s3-website.us-east-2.amazonaws.com/api/',
                scope: "openid profile email offline_access",
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            logoutParams={{
                returnTo: origin
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <NavBar />
                    <CryptoTicker />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/auth/callback" element={<CallbackPage />} />
                        <Route
                            path="/trade"
                            element={
                                <ProtectedRoute
                                    requiredPermissions={['read:trades', 'write:trades']}
                                >
                                    <TradingPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute
                                    requiredPermissions={['view:dashboard']}
                                >
                                    <DashboardPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/portfolio-analytics"
                            element={
                                <ProtectedRoute>
                                    <PortfolioAnalytics />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/settings/*"
                            element={
                                <ProtectedRoute
                                    requiredPermissions={['manage:settings', 'manage:users']}
                                >
                                    <SettingsPage />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                        style={{
                            zIndex: 9999,
                        }}
                        toastStyle={{
                            borderRadius: '4px',
                            fontSize: '14px',
                        }}
                    />
                    <SessionWarning />
                </Router>
            </ThemeProvider>
        </Auth0Provider>
    );
};

export default App;