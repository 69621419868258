import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import 'chart.js/auto';

const TradeChart = ({ trades }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Trade Prices',
            data: [],
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
            fill: true,
        }]
    });

    useEffect(() => {
        console.log('Received trades:', trades);
        if (trades.length > 0) {
            const labels = trades.map(trade => {
                const date = new Date(trade.timestamp);
                console.log('Processing timestamp:', trade.timestamp, 'Parsed date:', date);
                return isNaN(date.getTime()) ? 'Invalid Date' : format(date, 'yyyy-MM-dd HH:mm:ss.SSS');
            });
            const data = trades.map(trade => trade.tradedPrice);
            console.log('Processed labels:', labels);
            console.log('Processed data:', data);

            const newChartData = {
                labels,
                datasets: [
                    {
                        label: 'Trade Prices',
                        data,
                        borderColor: 'rgba(75,192,192,1)',
                        backgroundColor: 'rgba(75,192,192,0.2)',
                        fill: true,
                    }
                ]
            };
            setChartData(newChartData);
        }
    }, [trades]);

    return (
        <div>
            {trades.length === 0 ? (
                <div>No trade data available</div>
            ) : (
                <Line data={chartData} />
            )}
        </div>
    );
};

export default TradeChart;
