// src/config.js

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';

const API_CONFIGS = {
    local: {
        javaBackend: '/api', // This will be proxied to http://localhost:8080/api
        nodeBackend: 'http://localhost:3002',
    },
    dev: {
        javaBackend: '/api', // This will be proxied to http://localhost:8080/api
        nodeBackend: 'http://localhost:3002',
    },
    qa: {
        javaBackend: `${process.env.REACT_APP_JAVA_BACKEND_URL}/api`,
        nodeBackend: process.env.REACT_APP_NODE_BACKEND_URL,
    },
    production: {
        javaBackend: process.env.REACT_APP_JAVA_BACKEND_URL,
        nodeBackend: process.env.REACT_APP_NODE_BACKEND_URL,
    },
};

const config = {
    environment: ENVIRONMENT,
    apiUrls: API_CONFIGS[ENVIRONMENT] || API_CONFIGS.local,
    auth0: {
        domain: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-wrmiox1vscj57fii.us.auth0.com',
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'ECFMzbzyiuY3sX9BhQDSLHDY29OV9J1Y',
        callbackPath: process.env.REACT_APP_AUTH0_CALLBACK_PATH || '/auth/callback',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'http://radix-ui.s3-website.us-east-2.amazonaws.com/api/'
    }
};

export default config;

export const getApiUrl = (service) => {
    const url = config.apiUrls[service];
    if (!url) {
        throw new Error(`No URL configured for service: ${service}`);
    }
    return url;
};

// Helper function to get full callback URL for Auth0
export const getCallbackUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}${config.auth0.callbackPath}`;
};