import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const SessionWarning = () => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const [showWarning, setShowWarning] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        let warningTimer;
        let countdownInterval;

        const checkSessionExpiry = async () => {
            if (isAuthenticated) {
                const claims = await getIdTokenClaims();
                const expiryTime = claims?.exp * 1000; // Convert to milliseconds
                const warningThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds

                const timeUntilExpiry = expiryTime - Date.now();
                if (timeUntilExpiry <= warningThreshold && timeUntilExpiry > 0) {
                    setTimeRemaining(Math.floor(timeUntilExpiry / 1000));
                    setShowWarning(true);

                    countdownInterval = setInterval(() => {
                        setTimeRemaining(prev => {
                            if (prev <= 1) {
                                clearInterval(countdownInterval);
                                return 0;
                            }
                            return prev - 1;
                        });
                    }, 1000);
                }

                warningTimer = setTimeout(checkSessionExpiry, 60000); // Check every minute
            }
        };

        checkSessionExpiry();

        return () => {
            clearTimeout(warningTimer);
            clearInterval(countdownInterval);
        };
    }, [isAuthenticated, getIdTokenClaims]);

    const handleRefresh = () => {
        window.location.reload();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
            <DialogTitle>Session Expiring Soon</DialogTitle>
            <DialogContent>
                <Typography>
                    Your session will expire in {formatTime(timeRemaining)}. Would you like to refresh your session?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowWarning(false)} color="secondary">
                    Dismiss
                </Button>
                <Button onClick={handleRefresh} color="primary" variant="contained">
                    Refresh Session
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionWarning;