import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

const testimonialsData = [
    {
        name: 'Alex Johnson',
        testimonial: 'The user experience is fantastic. I’ve been able to triple my portfolio thanks to Radix’s tools and analytics.',
    },
    {
        name: 'Maria Garcia',
        testimonial: 'As a professional trader, I appreciate the robustness and reliability of the Radix trading platform.',
    },
    {
        name: 'Sam Lee',
        testimonial: 'Their customer service is top-notch. Issues are resolved promptly, and the staff is very knowledgeable.',
    },
];

const TestimonialItem = ({ name, testimonial }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Paper elevation={3} sx={{ padding: 2, margin: 1, height: '100%' }}>
            <Typography variant="subtitle1" component="h3">
                {name}
            </Typography>
            <Typography variant="body2">
                {testimonial}
            </Typography>
        </Paper>
    </Grid>
);

const TestimonialsSection = () => (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Typography variant="h4" component="h2" gutterBottom>
            Testimonials
        </Typography>
        <Grid container spacing={2}>
            {testimonialsData.map((testimonial, index) => (
                <TestimonialItem key={index} {...testimonial} />
            ))}
        </Grid>
    </Box>
);

export default TestimonialsSection;
