import React from 'react';
import './Footer.css';
import { Box, Typography, Container, Link } from '@mui/material';

const Footer = () => (
    <Box component="footer" className="footer">
        <Container maxWidth="lg" className="footer-content">
            <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem'
                }}
            >
                <span>Radix Trading Platform</span>
                <span>Revolutionizing digital assets trading for everyone.</span>
                <span>
                    {'Copyright © '}
                    <Link
                        color="inherit"
                        href="https://radix.example.com/"
                        sx={{ '&:hover': { opacity: 0.8 } }}
                    >
                        Radix
                    </Link>{' '}
                    {new Date().getFullYear()}.
                </span>
            </Typography>
        </Container>
    </Box>
);

export default Footer;