import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';

const featuresData = [
    {
        title: 'Ultra-Fast Execution',
        description: 'Trade with lightning-fast order execution, ensuring you never miss a market move.',
    },
    {
        title: 'Advanced Charting Tools',
        description: 'Access professional-grade charting tools to make informed trading decisions.',
    },
    {
        title: 'Robust Security',
        description: 'Your security is our priority. Trade with confidence knowing your data and assets are protected.',
    },
    {
        title: '24/7 Support',
        description: 'Our dedicated support team is available around the clock to help with any issues or questions.',
    },
    {
        title: 'Liquidity Aggregation',
        description: 'Get the best prices from multiple liquidity providers in our aggregation pool.',
    },
    {
        title: 'Institutional-Grade Infrastructure',
        description: 'Our platform is built on institutional-grade infrastructure, offering reliability and scalability.',
    },
];

const FeatureItem = ({ title, description }) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: 2, margin: 1, height: '100%' }}>
                <Typography variant="h6" component="h3" gutterBottom>
                    {title}
                </Typography>
                <Typography>
                    {description}
                </Typography>
            </Paper>
        </Grid>
    );
};

const FeaturesSection = () => {
    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" component="h2" gutterBottom>
                Platform Features
            </Typography>
            <Grid container spacing={2}>
                {featuresData.map((feature, index) => (
                    <FeatureItem key={index} title={feature.title} description={feature.description} />
                ))}
            </Grid>
        </Box>
    );
};

export default FeaturesSection;
