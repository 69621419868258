import React from 'react';
import './MainContent.css';
import TradeChart from '../TradeChart/TradeChart';

const MainContent = ({ children, trades = [], showChart = true }) => {
    return (
        <div className="main-content">
            <div className="main-content-inner">
                {children}
                {showChart && (
                    trades.length === 0 ? (
                        <div className="chart-empty-state">
                            <h3>Live Trade Chart</h3>
                            <p>Price chart will appear here as trades execute</p>
                        </div>
                    ) : (
                        <TradeChart trades={trades} />
                    )
                )}
            </div>
        </div>
    );
};

export default MainContent;