import React from 'react';
import { Box, Typography, Grid, Paper, Link } from '@mui/material';

const newsData = [
    {
        title: 'Radix Trading Platform Launches New Features',
        excerpt: 'The latest update includes advanced charting tools and improved security measures...',
        url: '#',
    },
    {
        title: 'Market Analysis: Trends to Watch in Q1',
        excerpt: 'Our experts share insights on the upcoming trends in the digital assets market for the next quarter...',
        url: '#',
    },
    {
        title: 'Interview with the Radix Team',
        excerpt: 'A deep dive into the vision and future plans of Radix with the Team...',
        url: '#',
    },
];

const NewsItem = ({ title, excerpt, url }) => (
    <Grid item xs={12} md={4}>
        <Paper elevation={3} sx={{ padding: 2, margin: 1, height: '100%' }}>
            <Typography variant="h6" component="h3" gutterBottom>
                <Link href={url} color="inherit" underline="none">
                    {title}
                </Link>
            </Typography>
            <Typography variant="body2">
                {excerpt}
            </Typography>
        </Paper>
    </Grid>
);

const LatestNewsSection = () => (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Typography variant="h4" component="h2" gutterBottom>
            Latest News
        </Typography>
        <Grid container spacing={2}>
            {newsData.map((news, index) => (
                <NewsItem key={index} {...news} />
            ))}
        </Grid>
    </Box>
);

export default LatestNewsSection;
