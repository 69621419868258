// ApiSettings.js
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Divider, MenuItem, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon, Info as InfoIcon } from '@mui/icons-material';

const ApiSettings = () => {
    const [apiKey] = useState('Your-API-Key-Here');
    const [rateLimitTier, setRateLimitTier] = useState('free');
    const [marketDataQueryCost] = useState(10);
    const [tradeDataQueryCost] = useState(5);

    const handleCopyApiKey = () => {
        navigator.clipboard.writeText(apiKey);
        alert('API Key copied to clipboard!');
    };

    const rateLimitTiers = [
        { value: 'free', label: 'Free - 10 requests/min' },
        { value: 'pro', label: 'Pro - 100 requests/min' },
        { value: 'enterprise', label: 'Enterprise - 1000 requests/min' },
    ];

    const handleRateLimitTierChange = (e) => {
        setRateLimitTier(e.target.value);
        // Handle backend logic for changing the user's tier
    };

    const handleUpgradePlan = () => {
        alert('Redirecting to upgrade page...');
        // Redirect or handle plan upgrade logic
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                API Settings
            </Typography>

            <Box sx={{ my: 2 }}>
                <Typography variant="h6">API Key Management</Typography>
                <TextField
                    fullWidth
                    label="API Key"
                    value={apiKey}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <Button onClick={handleCopyApiKey} size="small">
                                <FileCopyIcon fontSize="small" />
                            </Button>
                        ),
                    }}
                    sx={{ mt: 2 }}
                />
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Regenerate API Key
                </Button>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ my: 2 }}>
                <Typography variant="h6">Rate Limiting & Tier Management</Typography>
                <TextField
                    select
                    label="Current Tier"
                    value={rateLimitTier}
                    onChange={handleRateLimitTierChange}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    {rateLimitTiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Tooltip title="Upgrade your plan for higher rate limits and more features.">
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleUpgradePlan}>
                        Upgrade Plan
                        <InfoIcon fontSize="small" sx={{ ml: 1 }} />
                    </Button>
                </Tooltip>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ my: 2 }}>
                <Typography variant="h6">Market Data Access</Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Query market data with a cost of {marketDataQueryCost} credits per query.
                </Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                    View Market Data Documentation
                </Button>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ my: 2 }}>
                <Typography variant="h6">Trade Data Access</Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Query your trade data with a cost of {tradeDataQueryCost} credits per query.
                </Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                    View Trade Data Documentation
                </Button>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ my: 2 }}>
                <Typography variant="h6">Webhook Configuration</Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Configure webhooks to receive real-time notifications for your trades, balances, and more.
                </Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
                    Configure Webhooks
                </Button>
            </Box>
        </Box>
    );
};

export default ApiSettings;
