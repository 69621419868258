import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Button, Select, MenuItem, FormControl, InputLabel, Box,
    CircularProgress, TextField, TablePagination, Chip, Alert, Tooltip,
    NoSsr
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SearchX, History, RefreshCw } from 'lucide-react';
import dayjs from 'dayjs';
import { useAuth0 } from "@auth0/auth0-react";
import { getApiUrl } from '../../config';

const EmptyStateMessage = ({ isFiltered, onReset }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
            minHeight: '200px'
        }}
    >
        {isFiltered ? (
            <>
                <SearchX size={48} color="#666" style={{ marginBottom: '16px' }} />
                <Typography variant="h6" color="textSecondary" gutterBottom>
                    No trades found for these filters
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    Try adjusting your search criteria
                </Typography>
                <Button variant="outlined" onClick={onReset}>
                    Reset Filters
                </Button>
            </>
        ) : (
            <>
                <History size={48} color="#666" style={{ marginBottom: '16px' }} />
                <Typography variant="h6" color="textSecondary" gutterBottom>
                    No trade history available yet
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Trade history will appear here once you start trading
                </Typography>
            </>
        )}
    </Box>
);

const TradeHistory = () => {
    const [trades, setTrades] = useState([]);
    const [filters, setFilters] = useState({
        startDate: dayjs().subtract(7, 'day'),
        endDate: dayjs(),
        asset: '',
        side: '',
        status: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { getAccessTokenSilently } = useAuth0();

    const fetchTradeHistory = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${getApiUrl('javaBackend')}/trade-history`, {
                params: {
                    startDate: filters.startDate.toISOString(),
                    endDate: filters.endDate.toISOString(),
                    asset: filters.asset,
                    side: filters.side,
                    status: filters.status,
                    page,
                    limit: rowsPerPage
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setTrades(response.data);
        } catch (err) {
            console.error('Error fetching trade history:', err);
            setError('Failed to fetch trade history. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently, filters, page, rowsPerPage]);

    useEffect(() => {
        fetchTradeHistory();
    }, [fetchTradeHistory]);

    const handleReset = () => {
        setFilters({
            startDate: dayjs().subtract(7, 'day'),
            endDate: dayjs(),
            asset: '',
            side: '',
            status: ''
        });
        setPage(0);
    };

    const isFiltered = Boolean(
        filters.asset ||
        filters.side ||
        filters.status ||
        !filters.startDate.isSame(dayjs().subtract(7, 'day'), 'day') ||
        !filters.endDate.isSame(dayjs(), 'day')
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                    <Typography variant="h4" component="h1">Trade History</Typography>
                    <Button
                        startIcon={<RefreshCw />}
                        onClick={fetchTradeHistory}
                        disabled={isLoading}
                    >
                        Refresh
                    </Button>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                        {error}
                    </Alert>
                )}

                <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
                    <DatePicker
                        label="Start Date"
                        value={filters.startDate}
                        onChange={(date) => setFilters(prev => ({ ...prev, startDate: date }))}
                        disabled={isLoading}
                    />
                    <DatePicker
                        label="End Date"
                        value={filters.endDate}
                        onChange={(date) => setFilters(prev => ({ ...prev, endDate: date }))}
                        disabled={isLoading}
                    />
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Asset</InputLabel>
                        <Select
                            value={filters.asset}
                            label="Asset"
                            onChange={(e) => setFilters(prev => ({ ...prev, asset: e.target.value }))}
                            disabled={isLoading}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="BTC">BTC</MenuItem>
                            <MenuItem value="ETH">ETH</MenuItem>
                            <MenuItem value="SOL">SOL</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Side</InputLabel>
                        <Select
                            value={filters.side}
                            label="Side"
                            onChange={(e) => setFilters(prev => ({ ...prev, side: e.target.value }))}
                            disabled={isLoading}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="BUY">Buy</MenuItem>
                            <MenuItem value="SELL">Sell</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <NoSsr>
                    <TableContainer component={Paper}>
                        {isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                <CircularProgress />
                            </Box>
                        ) : trades.length === 0 ? (
                            <EmptyStateMessage isFiltered={isFiltered} onReset={handleReset} />
                        ) : (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Asset</TableCell>
                                            <TableCell>Side</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Total</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {trades.map((trade) => (
                                            <TableRow key={trade.id}>
                                                <TableCell>
                                                    {dayjs(trade.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                                                </TableCell>
                                                <TableCell>{trade.asset}</TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={trade.side}
                                                        color={trade.side === 'BUY' ? 'success' : 'error'}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    ${Number(trade.price).toFixed(2)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Number(trade.amount).toFixed(8)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    ${(Number(trade.price) * Number(trade.amount)).toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={trade.status}
                                                        color={
                                                            trade.status === 'FILLED' ? 'success' :
                                                                trade.status === 'PARTIALLY_FILLED' ? 'warning' :
                                                                    'default'
                                                        }
                                                        size="small"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={-1}  // -1 indicates unknown total count
                                    page={page}
                                    onPageChange={(_, newPage) => setPage(newPage)}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={(e) => {
                                        setRowsPerPage(parseInt(e.target.value, 10));
                                        setPage(0);
                                    }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                />
                            </>
                        )}
                    </TableContainer>
                </NoSsr>
            </Box>
        </LocalizationProvider>
    );
};

export default TradeHistory;