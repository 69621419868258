import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth0} from "@auth0/auth0-react";
import Sidebar from '../components/Sidebar/Sidebar';
import MainContent from '../components/MainContent/MainContent';
import Footer from '../components/Footer/Footer';
import GridContainer from '../components/GridContainer/GridContainer';
import TradeAnalytics from '../components/TradeAnalytics/TradeAnalytics';
import TradeHistory from "../components/TradeHistory/TradeHistory";
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {getApiUrl} from '../config';

function TradingPage() {
    const { getAccessTokenSilently, user } = useAuth0();
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);
    const [trades, setTrades] = useState([]);
    const [lastOrderDetails, setLastOrderDetails] = useState(null);
    const [order, setOrder] = useState({
        symbol: "BTC",
        side: "1",
        price: "",
        quantity: "",
        timestamp: null,
        orderType: "MARKET",
        status: "NEW",
        expiryTimestamp: dayjs().add(1, 'day').unix(),
    });
    const [errors, setErrors] = useState({});
    const [feedback, setFeedback] = useState('');
    const [orderResponse, setOrderResponse] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    const open = Boolean(anchorEl);
    const placeOrdersUrl = `${getApiUrl('javaBackend')}/trade`;
    const connectWebSocket = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const socket = new WebSocket(`ws://localhost:8080/trade-updates?token=${token}`);

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onmessage = (event) => {
            const tradeUpdate = JSON.parse(event.data);
            console.log('Received trade update:', tradeUpdate);
            handleOrderUpdate(tradeUpdate);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
            // Attempt to reconnect after a delay
            setTimeout(connectWebSocket, 5000);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return socket;
    }, [getAccessTokenSilently]);

    useEffect(() => {
        let socket;
        const setupSocket = async () => {
            socket = await connectWebSocket();
        };
        setupSocket();
        return () => {
            if (socket) socket.close();
        };
    }, [connectWebSocket]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCancelAllOrders = async () => {
        try {
            const token = await getAccessTokenSilently();
            await axios.post(
                `${getApiUrl('javaBackend')}/cancel-all-orders`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setBuyOrders([]);
            setSellOrders([]);
            setFeedback('All orders canceled successfully');
        } catch (error) {
            console.error('Error canceling all orders:', error);
            setFeedback('Error canceling all orders. Please try again.');
        }
        handleMenuClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrder(prevOrder => ({
            ...prevOrder,
            [name]: value
        }));
        // Clear any existing error for this field
        setErrors(prev => ({
            ...prev,
            [name]: undefined
        }));
    };

    const handleExpiryTimestampChange = (newValue) => {
        if (newValue.isAfter(dayjs())) {
            setOrder(prevOrder => ({
                ...prevOrder,
                expiryTimestamp: newValue.unix(),
            }));
        }
    };

    const validateOrder = (order) => {
        let errors = {};
        if (!order.quantity || parseFloat(order.quantity) <= 0) {
            errors.quantity = 'Quantity must be greater than zero.';
        }
        if (order.orderType === 'LIMIT' && (!order.price || parseFloat(order.price) <= 0)) {
            errors.price = 'Price must be greater than zero for limit orders.';
        }
        if (!order.symbol) {
            errors.symbol = 'Symbol is required.';
        }
        if (!order.side) {
            errors.side = 'Side is required.';
        }
        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateOrder(order);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setFeedback('Please correct the errors before submitting.');
            return;
        }

        setErrors({});
        setFeedback('Submitting order...');

        try {
            const token = await getAccessTokenSilently();
            const response = await axios.post(placeOrdersUrl, order, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.error) {
                setFeedback(response.data.error);
                return;
            }

            const newOrder = response.data.order;
            setOrderResponse(newOrder);
            setLastOrderDetails({ order: newOrder });

            // Reset form
            setOrder({
                symbol: "BTC",
                side: "1",
                price: "",
                quantity: "",
                timestamp: null,
                orderType: "MARKET",
                status: "NEW",
                expiryTimestamp: dayjs().add(1, 'day').unix(),
            });

            setFeedback('Order submitted successfully!');

            // Update order lists
            if (newOrder.side === 1) {
                setBuyOrders(prevOrders => [...prevOrders, newOrder]);
            } else if (newOrder.side === 2) {
                setSellOrders(prevOrders => [...prevOrders, newOrder]);
            }

            console.log('Order submitted:', response.data);
        } catch (error) {
            console.error('Error submitting order:', error);
            setFeedback('Error submitting order. Please try again.');
        }
    };

    const handleOrderUpdate = (tradeUpdate) => {
        console.log('Handling order update:', tradeUpdate);

        setBuyOrders(prevOrders => {
            return prevOrders.map(order => {
                if (order.clientOrderId === tradeUpdate.buyClientOrderId) {
                    return {
                        ...order,
                        status: tradeUpdate.tradedQuantity < order.quantity ? 'PARTIALLY_FILLED' : 'FILLED',
                        fillPrice: tradeUpdate.fillPrice,
                        fillTime: tradeUpdate.fillTime,
                        fillQty: tradeUpdate.tradedQuantity,
                    };
                }
                return order;
            });
        });

        setSellOrders(prevOrders => {
            return prevOrders.map(order => {
                if (order.clientOrderId === tradeUpdate.sellClientOrderId) {
                    return {
                        ...order,
                        status: tradeUpdate.tradedQuantity < order.quantity ? 'PARTIALLY_FILLED' : 'FILLED',
                        fillPrice: tradeUpdate.fillPrice,
                        fillTime: tradeUpdate.fillTime,
                        fillQty: tradeUpdate.tradedQuantity,
                    };
                }
                return order;
            });
        });

        setTrades(prevTrades => {
            console.log('Adding trade to trades:', tradeUpdate);
            return [...prevTrades, tradeUpdate];
        });
    };

    const handleCancelOrder = async (orderId) => {
        try {
            const token = await getAccessTokenSilently();
            await axios.post(
                `${getApiUrl('javaBackend')}/cancel-order`,
                { orderId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // Update the order lists to reflect the cancellation
            const updateOrderList = (orders) =>
                orders.map(order =>
                    order.clientOrderId === orderId
                        ? { ...order, status: 'CANCELED' }
                        : order
                );

            setBuyOrders(updateOrderList);
            setSellOrders(updateOrderList);

            setFeedback(`Order ${orderId} canceled successfully`);
        } catch (error) {
            console.error('Error canceling order:', error);
            setFeedback('Error canceling order. Please try again.');
        }
    };
    const renderLastOrderDetailsComponent = () => {
        if (!lastOrderDetails || !lastOrderDetails.order) return null;
        const { clientOrderId, symbol, price, quantity, status, expiryTimestamp } = lastOrderDetails.order;
        return (
            <Paper style={{ padding: '10px', marginTop: '10px', backgroundColor: '#e8f4fc' }}>
                <Typography variant="h6" data-testid="last-order-title">Last Order Details</Typography>
                <Typography data-testid="last-order-id"><strong>Order ID:</strong> {clientOrderId}</Typography>
                <Typography data-testid="last-order-symbol"><strong>Symbol:</strong> {symbol}</Typography>
                <Typography data-testid="last-order-price"><strong>Price:</strong> {price}</Typography>
                <Typography data-testid="last-order-quantity"><strong>Quantity:</strong> {quantity}</Typography>
                <Typography data-testid="last-order-status"><strong>Status:</strong> {status}</Typography>
                <Typography data-testid="last-order-expiry">
                    <strong>Expiry Timestamp:</strong> {dayjs.unix(expiryTimestamp).format('YYYY-MM-DD HH:mm:ss')}
                </Typography>
            </Paper>
        );
    };

    const renderOrdersTable = (orders, title) => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }} data-testid={`${title.toLowerCase()}-orders-table`}>
            <Typography variant="h5" align="center" sx={{ marginY: 1, backgroundColor: '#f3f3f3', padding: '10px' }}>
                {title} Orders
            </Typography>
            <Table sx={{ minWidth: 650, '& .MuiTableCell-root': { padding: '8px' } }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            Cancel
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                size="small"
                                sx={{ ml: 1 }}
                                data-testid="more-actions-button"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleCancelAllOrders} data-testid="cancel-all-orders">
                                    Cancel All Orders
                                </MenuItem>
                            </Menu>
                        </TableCell>
                        <TableCell align="center">Order ID</TableCell>
                        <TableCell align="center">Price(USD)</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Fill Price</TableCell>
                        <TableCell align="center">Fill Time</TableCell>
                        <TableCell align="center">Fill Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => (
                        <TableRow
                            key={order.clientOrderId}
                            data-testid={`order-row-${order.clientOrderId}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f0f0f0' } }}
                        >
                            <TableCell align="center">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleCancelOrder(order.clientOrderId)}
                                    data-testid={`cancel-button-${order.clientOrderId}`}
                                >
                                    Cancel
                                </Button>
                            </TableCell>
                            <TableCell align="center" data-testid={`order-id-${order.clientOrderId}`}>
                                {order.clientOrderId}
                            </TableCell>
                            <TableCell align="center" data-testid={`price-${order.clientOrderId}`}>
                                {order.price}
                            </TableCell>
                            <TableCell align="center" data-testid={`quantity-${order.clientOrderId}`}>
                                {order.quantity}
                            </TableCell>
                            <TableCell align="center" data-testid={`status-${order.clientOrderId}`}>
                                {order.status}
                            </TableCell>
                            <TableCell
                                align="center"
                                data-testid={`fill-price-${order.clientOrderId}`}
                            >
                                {order.fillPrice || 'N/A'}
                            </TableCell>
                            <TableCell align="center" data-testid={`fill-time-${order.clientOrderId}`}>
                                {order.fillTime ? dayjs.unix(order.fillTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                            </TableCell>
                            <TableCell align="center" data-testid={`fill-qty-${order.clientOrderId}`}>
                                {order.fillQty || 'N/A'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GridContainer>
                <Sidebar setActiveTab={setActiveTab} />
                <MainContent trades={trades} showChart={activeTab === 0}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="trading tabs">
                        <Tab label="Make a Trade" data-testid="make-trade-tab" />
                        <Tab label="Trade Analytics" data-testid="trade-analytics-tab" />
                        <Tab label="Trade History" data-testid="trade-history-tab" />
                    </Tabs>

                    {activeTab === 0 && (
                        <Box sx={{ mt: 2 }}>
                            <form onSubmit={handleSubmit} data-testid="trading-form">
                                <h2 data-testid="trading-form-title">Make a Trade</h2>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                    <TextField
                                        select
                                        label="Symbol"
                                        name="symbol"
                                        value={order.symbol}
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            'data-testid': 'symbol-select',
                                            'aria-label': 'Symbol'
                                        }}
                                        error={!!errors.symbol}
                                        helperText={errors.symbol}
                                    >
                                        <MenuItem value="BTC" data-testid="symbol-option-btc">BTC</MenuItem>
                                        <MenuItem value="ETH" data-testid="symbol-option-eth">ETH</MenuItem>
                                    </TextField>

                                    <TextField
                                        select
                                        label="Side"
                                        name="side"
                                        value={order.side}
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            'data-testid': 'side-select',
                                            'aria-label': 'Side'
                                        }}
                                        error={!!errors.side}
                                        helperText={errors.side}
                                    >
                                        <MenuItem value="1" data-testid="side-option-buy">Buy</MenuItem>
                                        <MenuItem value="2" data-testid="side-option-sell">Sell</MenuItem>
                                    </TextField>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <TextField
                                        label="Price"
                                        name="price"
                                        type="number"
                                        value={order.price}
                                        onChange={handleChange}
                                        required={order.orderType === 'LIMIT'}
                                        sx={{ flexBasis: '30%' }}
                                        inputProps={{
                                            'data-testid': 'price-input',
                                            'aria-label': 'Price'
                                        }}
                                        error={!!errors.price}
                                        helperText={errors.price}
                                    />

                                    <TextField
                                        label="Quantity"
                                        name="quantity"
                                        type="number"
                                        value={order.quantity}
                                        onChange={handleChange}
                                        required
                                        sx={{ flexBasis: '30%' }}
                                        inputProps={{
                                            'data-testid': 'quantity-input',
                                            'aria-label': 'Quantity'
                                        }}
                                        error={!!errors.quantity}
                                        helperText={errors.quantity}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <TextField
                                        select
                                        label="Order Type"
                                        name="orderType"
                                        value={order.orderType}
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            'data-testid': 'order-type-select',
                                            'aria-label': 'Order Type'
                                        }}
                                    >
                                        <MenuItem value="MARKET" data-testid="order-type-market">Market</MenuItem>
                                        <MenuItem value="LIMIT" data-testid="order-type-limit">Limit</MenuItem>
                                    </TextField>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                                    <DateTimePicker
                                        label="Expiry Timestamp"
                                        value={dayjs.unix(order.expiryTimestamp)}
                                        onChange={handleExpiryTimestampChange}
                                        minDateTime={dayjs()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                sx={{ flexBasis: '30%' }}
                                                data-testid="expiry-timestamp-input"
                                            />
                                        )}
                                    />
                                </Box>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    sx={{ mt: 2 }}
                                    data-testid="submit-order-button"
                                >
                                    Submit Order
                                </Button>
                                {feedback && (
                                    <Typography
                                        color={feedback.includes('Error') ? 'error' : 'success'}
                                        sx={{ my: 2 }}
                                        data-testid="feedback-message"
                                    >
                                        {feedback}
                                    </Typography>
                                )}
                                {renderLastOrderDetailsComponent()}
                            </form>

                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    {renderOrdersTable(buyOrders, 'Buy')}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    {renderOrdersTable(sellOrders, 'Sell')}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {activeTab === 1 && (
                        <TradeAnalytics userId={user.sub} />
                    )}

                    {activeTab === 2 && (
                        <TradeHistory />
                    )}
                </MainContent>
                <Footer />
            </GridContainer>
        </LocalizationProvider>
    );
}

export default TradingPage;