import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { getApiUrl } from '../../config';
import {
    Typography,
    TextField,
    Button,
    Box,
    Grid,
    Paper,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';

const ProfileSettings = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const token = await getAccessTokenSilently();
                console.log('Fetching user profile from:', `${getApiUrl('javaBackend')}/user/profile`);
                const response = await axios.get(`${getApiUrl('javaBackend')}/user/profile`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Received user profile:', response.data);
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching user profile:', error.response?.data || error.message);
                setSnackbar({ open: true, message: 'Error loading profile', severity: 'error' });
                // Fallback to Auth0 user data if API fails
                setFormData({
                    firstName: user?.given_name || '',
                    lastName: user?.family_name || '',
                    email: user?.email || '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [getAccessTokenSilently, user]); // Added user as dependency

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        try {
            const token = await getAccessTokenSilently();
            console.log('Sending PUT request to:', `${getApiUrl('javaBackend')}/user/profile`);
            console.log('Profile data being sent:', formData);
            const response = await axios.put(`${getApiUrl('javaBackend')}/user/profile`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Profile update response:', response.data);
            setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
        } catch (error) {
            console.error('Error updating profile:', error.response?.data || error.message);
            setSnackbar({ open: true, message: 'Error updating profile', severity: 'error' });
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                Profile Information
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            type="tel"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="State"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ZIP Code"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    disabled={saving}
                >
                    {saving ? 'Saving...' : 'Save Profile Information'}
                </Button>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default ProfileSettings;