import React from 'react';
import './Sidebar.css';
import { Button } from '@mui/material';

const Sidebar = ({ setActiveTab }) => {
    return (
        <div className="sidebar">
            <Button className="sidebar-button" variant="contained" onClick={() => setActiveTab(0)}>
                Make a Trade
            </Button>
            <Button className="sidebar-button" variant="contained" onClick={() => setActiveTab(1)}>
                Trade Analytics
            </Button>
            <Button className="sidebar-button" variant="contained" onClick={() => setActiveTab(2)}>
                Trade History
            </Button>
            <Button className="sidebar-button" variant="contained">
                Add Funds
            </Button>
            <Button className="sidebar-button" variant="contained">
                Help
            </Button>
            <Button className="sidebar-button" variant="contained">
                Contact Support
            </Button>
        </div>
    );
};

export default Sidebar;