// src/utils/notifications.js
import { toast } from 'react-toastify';

const customToastStyle = {
    success: {
        style: {
            background: '#1a1a1a',
            borderLeft: '4px solid #4caf50',
            color: '#fff',
            borderRadius: '4px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
        progressStyle: {
            background: '#4caf50',
        },
    },
    error: {
        style: {
            background: '#1a1a1a',
            borderLeft: '4px solid #f44336',
            color: '#fff',
            borderRadius: '4px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
        progressStyle: {
            background: '#f44336',
        },
    },
    info: {
        style: {
            background: '#1a1a1a',
            borderLeft: '4px solid #2196f3',
            color: '#fff',
            borderRadius: '4px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
        progressStyle: {
            background: '#2196f3',
        },
    },
    warning: {
        style: {
            background: '#1a1a1a',
            borderLeft: '4px solid #ff9800',
            color: '#fff',
            borderRadius: '4px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
        progressStyle: {
            background: '#ff9800',
        },
    },
};

export const notify = {
    success: (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToastStyle.success.style,
            progressStyle: customToastStyle.success.progressStyle,
        });
    },
    error: (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToastStyle.error.style,
            progressStyle: customToastStyle.error.progressStyle,
        });
    },
    warning: (message) => {
        toast.warning(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToastStyle.warning.style,
            progressStyle: customToastStyle.warning.progressStyle,
        });
    },
    info: (message) => {
        toast.info(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToastStyle.info.style,
            progressStyle: customToastStyle.info.progressStyle,
        });
    },
};