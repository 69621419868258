import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    CircularProgress,
    Alert,
    useMediaQuery,
    useTheme,
    Menu,
    MenuItem
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useAuth0 } from "@auth0/auth0-react";
import { notify } from '../../utils/notifications';
import MenuIcon from '@mui/icons-material/Menu';

const StyledButton = styled(Button)(({ active }) => ({
    color: '#fff',
    marginRight: '10px',
    '&.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    ...(active && {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    })
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
    width: 'calc(100% - 200px)',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

const NavBarTitle = styled(Typography)({
    flexGrow: 0,
    marginRight: 'auto',
});

const MobileMenu = styled('div')({
    padding: '20px',
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
});

const UserInfo = styled(Typography)({
    padding: '10px',
    color: 'white',
});

const NavBar = () => {
    const { isAuthenticated, isLoading, error, user, loginWithRedirect, logout } = useAuth0();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();

    useEffect(() => {
        setMobileMenuOpen(false);
    }, [location]);

    const handleLogin = () => {
        notify.info('Redirecting to login...');
        loginWithRedirect();
    };

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: 'https://radix.trading'
            }
        });
        notify.success('Logged out successfully');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const isActiveRoute = (path) => {
        return location.pathname === path;
    };

    const NavLinks = () => (
        <>
            <StyledButton
                component={RouterLink}
                to="/dashboard"
                className={isActiveRoute('/dashboard') ? 'Mui-selected' : ''}
                data-testid="dashboard-link"
            >
                Dashboard
            </StyledButton>
            <StyledButton
                component={RouterLink}
                to="/trade"
                className={isActiveRoute('/trade') ? 'Mui-selected' : ''}
                data-testid="trade-link"
            >
                Trade
            </StyledButton>
            <StyledButton
                component={RouterLink}
                to="/portfolio-analytics"
                className={isActiveRoute('/portfolio-analytics') ? 'Mui-selected' : ''}
                data-testid="analytics-link"
            >
                Portfolio Analytics
            </StyledButton>
            <StyledButton
                component={RouterLink}
                to="/settings"
                className={isActiveRoute('/settings') ? 'Mui-selected' : ''}
                data-testid="settings-link"
            >
                Settings
            </StyledButton>
            <StyledButton
                onClick={handleLogout}
                data-testid="logout-button"
            >
                Log Out
            </StyledButton>
        </>
    );

    if (isLoading) {
        return <CircularProgress data-testid="auth-loading" />;
    }

    if (error) {
        return <Alert data-testid="auth-error" severity="error">{error.message}</Alert>;
    }

    return (
        <AppBar position="static">
            <StyledToolbar>
                <NavBarTitle variant="h6" data-testid="navbar-title">
                    Radix
                </NavBarTitle>
                {isAuthenticated ? (
                    <>
                        {user && (
                            <UserInfo
                                variant="subtitle2"
                                data-testid="user-info"
                            >
                                {user.name}
                            </UserInfo>
                        )}
                        {isMobile ? (
                            <>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleMenuOpen}
                                    data-testid="mobile-menu-button"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="mobile-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    data-testid="mobile-menu"
                                >
                                    <MenuItem
                                        component={RouterLink}
                                        to="/dashboard"
                                        onClick={handleMenuClose}
                                        data-testid="mobile-dashboard-link"
                                    >
                                        Dashboard
                                    </MenuItem>
                                    <MenuItem
                                        component={RouterLink}
                                        to="/trade"
                                        onClick={handleMenuClose}
                                        data-testid="mobile-trade-link"
                                    >
                                        Trade
                                    </MenuItem>
                                    <MenuItem
                                        component={RouterLink}
                                        to="/portfolio-analytics"
                                        onClick={handleMenuClose}
                                        data-testid="mobile-analytics-link"
                                    >
                                        Portfolio Analytics
                                    </MenuItem>
                                    <MenuItem
                                        component={RouterLink}
                                        to="/settings"
                                        onClick={handleMenuClose}
                                        data-testid="mobile-settings-link"
                                    >
                                        Settings
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleMenuClose();
                                            handleLogout();
                                        }}
                                        data-testid="mobile-logout-button"
                                    >
                                        Log Out
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <NavLinks />
                        )}
                    </>
                ) : (
                    <StyledButton
                        onClick={handleLogin}
                        data-testid="login-button"
                    >
                        Log In / Sign Up
                    </StyledButton>
                )}
            </StyledToolbar>
        </AppBar>
    );
};

export default NavBar;