import React, { useState } from 'react';
import { Typography, TextField, Button, Box, MenuItem } from '@mui/material';

const DirectDeposit = () => {
    const [formData, setFormData] = useState({
        accountHolderName: '',
        bankName: '',
        accountNumber: '',
        routingNumber: '',
        accountType: '',
    });

    const accountTypes = [
        { value: 'checking', label: 'Checking' },
        { value: 'savings', label: 'Savings' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would normally handle form submission,
        // such as sending the data to an API or processing it.
        console.log('Form submitted:', formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
                Direct Deposit Settings
            </Typography>

            <TextField
                required
                fullWidth
                margin="normal"
                label="Account Holder Name"
                name="accountHolderName"
                value={formData.accountHolderName}
                onChange={handleChange}
            />

            <TextField
                required
                fullWidth
                margin="normal"
                label="Bank Name"
                name="bankName"
                value={formData.bankName}
                onChange={handleChange}
            />

            <TextField
                required
                fullWidth
                margin="normal"
                label="Account Number"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
                type="number"
            />

            <TextField
                required
                fullWidth
                margin="normal"
                label="Routing Number"
                name="routingNumber"
                value={formData.routingNumber}
                onChange={handleChange}
                type="number"
            />

            <TextField
                select
                required
                fullWidth
                margin="normal"
                label="Account Type"
                name="accountType"
                value={formData.accountType}
                onChange={handleChange}
            >
                {accountTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
                Save Direct Deposit Information
            </Button>
        </Box>
    );
};

export default DirectDeposit;
