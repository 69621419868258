import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for the hero section
const HeroContainer = styled(Box)(({ theme }) => ({
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    backgroundImage: 'url(/images/hero-image-4.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
}));

const HeroContent = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    maxWidth: '800px',
}));

const HeroTitle = styled(Typography)({
    marginBottom: '1rem',
    fontWeight: 700,
    fontSize: '3rem',
});

const HeroSubtitle = styled(Typography)({
    marginBottom: '2rem',
    color: '#ddd',
});

const HeroButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

// The HeroSection component
const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroContent>
                <HeroTitle variant="h2">
                    Trade Digital Assets Like a Pro
                </HeroTitle>
                <HeroSubtitle variant="h5">
                    Join the world's leading digital assets trading platform.
                </HeroSubtitle>
                <Grid container justifyContent="center">
                    <HeroButton variant="contained" color="primary" size="large">
                        Get Started
                    </HeroButton>
                    <HeroButton variant="outlined" color="inherit" size="large">
                        Learn More
                    </HeroButton>
                </Grid>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
