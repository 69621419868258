import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, Button } from '@mui/material';
import { saveAs } from 'file-saver'; // Ensure you have file-saver installed: npm install file-saver

const Activity = () => {
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        // Placeholder: Replace this with actual API call to fetch user activities
        const fetchedActivities = [
            { id: 1, type: 'Settings Update', description: 'Updated profile information', date: '2024-08-10 10:32:00' },
            { id: 2, type: 'Trade', description: 'Bought 1 BTC at $60,000', date: '2024-08-09 14:24:00' },
            { id: 3, type: 'Deposit', description: 'Deposited $5000 to account', date: '2024-08-08 09:15:00' },
            { id: 4, type: 'Withdrawal', description: 'Withdrew $2000 from account', date: '2024-08-07 16:45:00' },
            { id: 5, type: 'Settings Update', description: 'Enabled 2FA', date: '2024-08-06 12:00:00' },
        ];

        setActivities(fetchedActivities);
    }, []);

    const downloadCSV = () => {
        const headers = 'ID,Type,Description,Date\n';
        const rows = activities
            .map(activity => `${activity.id},${activity.type},${activity.description},${activity.date}`)
            .join('\n');

        const csvContent = `${headers}${rows}`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'activity_log.csv');
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
                Activity Log
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mb: 2 }}
                onClick={downloadCSV}
            >
                Download Activity Log
            </Button>
            <Paper elevation={3} sx={{ p: 2 }}>
                {activities.length === 0 ? (
                    <Typography variant="body1">No activities recorded.</Typography>
                ) : (
                    <List>
                        {activities.map((activity) => (
                            <ListItem key={activity.id} divider>
                                <ListItemText
                                    primary={`${activity.type}: ${activity.description}`}
                                    secondary={activity.date}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>
        </Box>
    );
};

export default Activity;
